import { useParams } from "react-router-dom";
import ApplicantList from "./applicantList";

export function Applicant() {
  const { id } = useParams();
  return (
    <div>
      <ApplicantList jobId={id} />
    </div>
  );
}
