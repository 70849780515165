import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase, // for Jobs
  faHandshake, // for Deals
  faSitemap, // for Directory
  // Add or replace icons as needed for other sections
  faBars,
  faBell, // for Notifications
  faEnvelope, // for Messages
  faUser, // for Profile
  faChartLine, // For Market Insights
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [profile, setProfile] = useState({
    profilePhoto: "",
  });
  const [notifications, setNotifications] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const email = sessionStorage.getItem("email");

  // Assuming you have a state for unread messages count, similar to notifications
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchSubscription = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://hotelhive-backend.vercel.app/api/subscription/${email}`
      ); // Assuming your Flask app runs on port 5000
      setSubscription(data);
      console.log("Subscription data:", data);
      if (!data) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
      setSubscription(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, [email]); // email as a dependency, assuming it's stored in session and available

  useEffect(() => {
    // Trim the email to remove any accidental leading/trailing whitespace
    const trimmedEmail = email.trim();

    const excludedEmails = [
      "timotius_albert@yahoo.com",
      "dpatel3368@gmail.com",
    ];

    if (
      !subscription &&
      !loading &&
      !excludedEmails.includes(trimmedEmail) &&
      window.location.pathname !== "/subscriptions"
    ) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [subscription, loading, email]);

  // Function to fetch unread messages count
  useEffect(() => {
    if (email) {
      fetchUnreadMessagesCount();
    }
  }, [email]);

  const fetchUnreadMessagesCount = () => {
    axios
      .get(
        `https://hotelhive-backend.vercel.app/api/messages/unread-count?email=${email}`
      )
      .then((response) => {
        setUnreadCount(response.data.unreadCount);
      })
      .catch((error) =>
        console.error("Error fetching unread messages count", error)
      );
  };

  const markMessagesAsRead = () => {
    axios
      .post("https://hotelhive-backend.vercel.app/api/messages/mark-as-read", {
        email,
      })
      .then(() => {
        setUnreadCount(0); // Reset unread count after marking messages as read
      })
      .catch((error) => console.error("Error marking messages as read", error));
  };

  const handleMessagesClick = () => {
    // Optionally, mark all messages as read in the backend here

    // Reset the unread messages count state
    setUnreadCount(0);

    // Redirect to the chat/messages page
    window.location.href = "/chat";
  };

  // Handler when

  const markNotificationAsRead = (notificationId) => {
    // Call the backend to mark the notification as read
    axios
      .post(
        `https://hotelhive-backend.vercel.app/api/notifications/${notificationId}/mark-read`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            // Any other headers like Authorization if required
          },
        }
      )
      .then(() => {
        // Filter out the read notification from the local state
        setNotifications(
          notifications.filter(
            (notification) => notification.id !== notificationId
          )
        );
      })
      .catch((error) =>
        console.error("Error marking notification as read:", error)
      );
  };

  const fetchNotifications = () => {
    if (email) {
      axios
        .get(
          `https://hotelhive-backend.vercel.app/api/notifications?email=${email}`
        )
        .then((response) => {
          setNotifications(response.data);
          const notifications = response.data;
          // Assuming each notification object has an 'is_read' field
          const unreadCount = notifications.filter(
            (notification) => !notification.is_read
          ).length;
          setUnreadNotificationsCount(unreadCount);
        })
        .catch((error) =>
          console.error("Could not fetch notifications", error)
        );
    }
  };

  useEffect(() => {
    fetchNotifications();
    // Polling interval for notifications
    const intervalId = setInterval(fetchNotifications, 30000); // 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [email]);
  useEffect(() => {
    if (email) {
      axios
        .get(
          `https://hotelhive-backend.vercel.app/api/notifications?email=${email}`
        )
        .then((response) => {
          setNotifications(response.data);
        })
        .catch((error) =>
          console.error("Could not fetch notifications", error)
        );
    }
  }, []);
  useEffect(() => {
    if (!email) {
      window.location.href = "/";
    }
    axios
      .get("https://hotelhive-backend.vercel.app/api/profile/" + email)
      .then((res) => {
        setProfile({
          ...profile,
          profilePhoto: res.data.profilePhoto,
        });
        console.log(res);
      })
      .catch((error) => console.error("Error fetching profile data:", error));
  }, [email]);
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-[#2544E3] shadow-xl">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              href="/main"
            >
              <img
                className="w-10"
                src={require("../images/HotelHive.png")}
              ></img>
            </a>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon
                icon={faBars}
                className="text-lg leading-lg text-white opacity-75"
              />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center justify-center " +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none justify-center  w-full ">
              {/* Directory */}
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/directories"
                >
                  <FontAwesomeIcon
                    icon={faSitemap}
                    className="text-2xl leading-lg text-white opacity-75 "
                  />
                  <span className="ml-2">Directory</span>
                </a>
              </li>

              {/* Jobs */}
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/jobs"
                >
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    className="text-2xl leading-lg text-white opacity-75"
                  />
                  <span className="ml-2">Jobs</span>
                </a>
              </li>

              {/* Deals */}
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/deals"
                >
                  <FontAwesomeIcon
                    icon={faHandshake}
                    className="text-2xl leading-lg text-white opacity-75"
                  />
                  <span className="ml-2">Deals</span>
                </a>
              </li>

              {/* Market Insights */}
              {/* <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#market-insights"
                >
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className="text-lg leading-lg text-white opacity-75"
                  />
                  <span className="ml-2">Market Insights</span>
                </a>
              </li> */}

              {/* News */}
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/news"
                >
                  <FontAwesomeIcon
                    icon={faNewspaper}
                    className="text-2xl leading-lg text-white opacity-75"
                  />
                  <span className="ml-2">News</span>
                </a>
              </li>

              {/* Additional sections like Market Insights and News can be added here following the same pattern */}

              <div className="flex lg:absolute right-10">
                {/* Notifications */}
                <div
                  className="relative"
                  onMouseLeave={() => setShowDropdown(false)}
                  onClick={() => {
                    const email = sessionStorage.getItem("email"); // Or however you're storing the user's email
                    axios
                      .post(
                        "https://hotelhive-backend.vercel.app/api/notifications/mark-all-read",
                        { email: email },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                      .then((response) => {
                        console.log(response.data.message);
                        // Optionally, trigger any state updates or UI feedback based on successful request
                      })
                      .catch((error) => {
                        console.error(
                          "Error marking notifications as read:",
                          error
                        );
                        // Handle error
                      });
                  }}
                >
                  <button
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      className="text-2xl leading-lg text-white opacity-75"
                    />
                    {unreadNotificationsCount > 0 && (
                      <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                        {unreadNotificationsCount}
                      </span>
                    )}

                    {/* <span className="ml-2">Notification</span> */}
                  </button>
                  {showDropdown && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10">
                      {notifications.length > 0 ? (
                        notifications.map((notification) => (
                          <a
                            key={notification.id}
                            href="#"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() =>
                              markNotificationAsRead(notification.id)
                            }
                          >
                            {notification.message}
                          </a>
                        ))
                      ) : (
                        <div className="px-4 py-2 text-sm text-gray-700">
                          No notifications
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Messages */}
                <li className="nav-item">
                  <a
                    className="px-3 relative py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/chat"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-2xl leading-lg text-white opacity-75"
                    />
                    {unreadCount > 0 && (
                      <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                        {unreadCount}
                      </span>
                    )}
                    {/* <span className="ml-2">Messages</span> */}
                  </a>
                </li>

                {/* Profile */}
                <li className="nav-item relative">
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75 cursor-pointer"
                    onClick={() => {
                      document
                        .getElementById("dropdown-menu")
                        .classList.toggle("hidden");
                    }}
                  >
                    {profile.profilePhoto ? (
                      <img
                        src={profile.profilePhoto}
                        alt="Profile"
                        className="rounded-full w-7 h-7 object-cover"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-lg leading-lg text-white opacity-75"
                      />
                    )}
                    <span className="ml-2">Profile</span>
                  </a>
                  {/* Dropdown Menu */}
                  <div
                    id="dropdown-menu"
                    className="absolute hidden group-hover:block bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
                  >
                    <a
                      href={"/profile/" + email}
                      className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-800 hover:bg-gray-100"
                    >
                      View Profile
                    </a>
                    <a
                      href="/subscriptions"
                      className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-800 hover:bg-gray-100"
                    >
                      Subscriptions
                    </a>
                    <a
                      href="/settings"
                      className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-800 hover:bg-gray-100"
                    >
                      Settings
                    </a>
                    <a
                      onClick={() => {
                        sessionStorage.removeItem("email");
                      }}
                      href="/"
                      className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-800 hover:bg-gray-100"
                    >
                      Logout
                    </a>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg w-96">
            <h2 className="text-2xl font-bold mb-2">Subscription Required</h2>
            <p className="mb-4">
              You need to subscribe to access this feature.
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  window.location.href = "/subscriptions";
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
              >
                Go to Subscriptions
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
