import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./navbar";
import PostJobForm from "./jobsForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactModal from "react-modal";
import { useMediaQuery } from "react-responsive";
import { faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [profile, setProfile] = useState({ profilePhoto: "", id: "" });
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [applicationData, setApplicationData] = useState({
    resume: null,
    coverLetter: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    sortBy: "new", // Default sort by 'new'
    listings: "all", // Default listings to 'all'
    category: "", // No category filter by default
    location: "", // No location filter by default
  });
  const [filteredJobs, setFilteredJobs] = useState([]);

  const openApplyModal = (job) => {
    setSelectedJob(job);
    setApplyModalOpen(true);
  };

  const isMobile = useMediaQuery({ maxWidth: 600 });
  useEffect(() => {
    fetchJobs();
  }, [filters]);

  const email = sessionStorage.getItem("email");
  useEffect(() => {
    if (email) {
      fetchProfile(email);
    }
  }, [email]);

  const fetchJobs = () => {
    setIsLoading(true);
    // const queryParams = new URLSearchParams({
    //   sortBy: filters.sortBy,
    //   category: filters.category,
    //   location: filters.location,
    //   // Add other filters as query parameters
    // }).toString();

    axios
      .get("https://hotelhive-backend.vercel.app/api/jobs", {
        headers: { Email: sessionStorage.getItem("email") }, // Assuming you store the user's email in sessionStorage
      })
      .then((response) => {
        setJobs(response.data);
        setFilteredJobs(response.data);
        setIsLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Could not fetch jobs", error);
        setIsLoading(false);
      });
  };

  const fetchProfile = (email) => {
    axios
      .get(`https://hotelhive-backend.vercel.app/api/profile/${email}`)
      .then((res) =>
        setProfile({
          ...profile,
          profilePhoto: res.data.profile_photo,
          id: res.data.id,
        })
      )
      .catch((error) => console.error("Error fetching profile data:", error));
  };

  const deleteJob = (jobId) => {
    axios
      .delete(`https://hotelhive-backend.vercel.app/api/jobs/${jobId}`)
      .then(() => {
        setJobs(jobs.filter((job) => job.id !== jobId));
        toast.success("Job deleted successfully!");
      })
      .catch((error) => {
        console.error("Failed to delete job", error);
        toast.error("Failed to delete job");
      });
  };

  const openDetailsModal = (job) => {
    setSelectedJob(job);
    setDetailsModalOpen(true);
  };

  const handleApply = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    const formData = new FormData();
    formData.append("resume", applicationData.resume);
    if (applicationData.coverLetter) {
      formData.append("coverLetter", applicationData.coverLetter);
    }

    try {
      await axios.post(
        `https://hotelhive-backend.vercel.app/api/jobs/${selectedJob.id}/apply`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Email: sessionStorage.getItem("email"), // Example header, adjust as needed
          },
        }
      );
      toast.success("Application submitted successfully!");
      window.location.href = "/jobs";
    } catch (error) {
      console.error("Failed to submit application:", error);
      toast.error("Failed to submit application");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  // Inside the Job Details Modal
  // const applyForJob = async () => {
  //   try {
  //     await axios.post(
  //       `https://hotelhive-backend.vercel.app/api/jobs/${selectedJob.id}/apply`,
  //       {},
  //       {
  //         headers: { Email: sessionStorage.getItem("email") }, // Assuming you store the user's email in sessionStorage
  //       }
  //     );
  //     toast.success("Application submitted successfully!");
  //     setDetailsModalOpen(false); // Close the modal after applying
  //   } catch (error) {
  //     console.error("Failed to apply for the job:", error);
  //     toast.error("Failed to apply for the job");
  //   }
  // };

  useEffect(() => {
    let tempJobs = [...jobs];

    // Sort by
    if (filters.sortBy === "new") {
      tempJobs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (filters.sortBy === "popular") {
      // Assuming you have a way to determine popularity
    }

    // Filter by category
    if (filters.category && filters.category !== "All") {
      tempJobs = tempJobs.filter(
        (job) => job.job_categories === filters.category
      );
    }

    // Filter by location
    if (filters.location) {
      tempJobs = tempJobs.filter((job) =>
        job.location.toLowerCase().includes(filters.location.toLowerCase())
      );
    }

    switch (filters.listings) {
      case "my_listings":
        tempJobs = tempJobs.filter((job) => job.user_id == profile.id); // Assuming currentUser.id exists
        break;
      case "applied":
        tempJobs = tempJobs.filter((job) => job.has_applied); // Assuming userApplications array exists
        break;
      case "not_applied":
        tempJobs = tempJobs.filter((job) => !job.has_applied); // Assuming userApplications array exists
        break;
    }

    setFilteredJobs(tempJobs);
  }, [jobs, filters]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const customStyles = {
    content: {
      position: "absolute",
      top: isMobile ? "50%" : "55%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? "90%" : "90%", // Adjust based on your mobile responsiveness
      backgroundColor: "white",
      padding: 20,
      borderRadius: "10px", // Optional for rounded corners
      maxHeight: "80vh", // Maximum height to ensure content is scrollable within the viewport
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <div>
      <Navbar />
      <div className="flex flex-col lg:flex-row">
        {/* Sidebar for Filters */}
        <div className="lg:w-1/4 p-10 shadow-md mt-6">
          <h3 className="font-bold mb-4">Filters</h3>
          {/* Sort By */}
          <div className="mb-4">
            <label className="block mb-2">Sort by:</label>
            <select
              value={filters.sortBy}
              onChange={(e) =>
                setFilters({ ...filters, sortBy: e.target.value })
              }
              className="w-full p-2 border rounded"
            >
              <option value="new">New</option>
              <option value="popular">Popular</option>
            </select>
          </div>
          {/* Listings */}
          <div className="mb-4">
            <label className="block mb-2">Listings:</label>
            <select
              value={filters.listings}
              onChange={(e) =>
                setFilters({ ...filters, listings: e.target.value })
              }
              className="w-full p-2 border rounded"
            >
              <option value="all">All</option>
              <option value="my_listings">My Listings</option>
              <option value="applied">Applied</option>
              <option value="not_applied">Not Applied</option>
            </select>
          </div>
          {/* Category */}
          <div className="mb-4">
            <label className="block mb-2">Category:</label>
            <select
              value={filters.category}
              onChange={(e) =>
                setFilters({ ...filters, category: e.target.value })
              }
              className="w-full p-2 border rounded"
            >
              <option value="">All</option>
              {/* <option value="All">All</option> */}
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Intern">Intern</option>
              <option value="Contract">Contract</option>
            </select>
          </div>
          {/* Location */}
          <div className="mb-4">
            <label className="block mb-2">Location:</label>
            <input
              type="text"
              value={filters.location}
              onChange={(e) =>
                setFilters({ ...filters, location: e.target.value })
              }
              className="w-full p-2 border rounded"
              placeholder="Enter location"
            />
          </div>
          {/* <button
            onClick={fetchJobs}
            className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Apply Filters
          </button> */}
        </div>

        {/* Main Content */}
        <div className="lg:w-3/4 p-4 overflow-auto">
          {/* Your existing job listings UI */}
          <PostJobForm
            profilePhoto={profile.profilePhoto}
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            onJobPosted={fetchJobs}
          />
          <div className="container mx-auto px-4">
            <div className="mt-6">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold">Jobs</h2>
                <button
                  onClick={() => setModalOpen(true)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Post a Job
                </button>
              </div>
              <div className="mt-4 overflow-x-auto">
                {isLoading ? (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
                ) : (
                  <table className="table-auto w-full">
                    <thead>
                      <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left"></th>
                        <th className="py-3 px-6 text-left">Description</th>
                        <th className="py-3 px-6 text-left">Location</th>
                        {/* <th className="py-3 px-6 text-left">School</th> */}
                        <th className="py-3 px-6 text-left">Created</th>
                        <th className="py-3 px-6 text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                      {filteredJobs.map((job) => (
                        <tr
                          key={job.id}
                          className="border-b border-gray-200 hover:bg-gray-100"
                        >
                          <td className="py-3 px-6 text-left">
                            <img
                              className="w-8 rounded-full"
                              src={job.profile_photo}
                            ></img>
                          </td>
                          <td className="py-3 px-6 text-left">{job.title}</td>
                          <td className="py-3 px-6 text-left">
                            {job.location}
                          </td>
                          {/* <td className="py-3 px-6 text-left">{job.school}</td> */}
                          <td className="py-3 px-6 text-left">
                            {new Date(job.created_at).toLocaleDateString()}
                          </td>
                          <td className="py-3 px-6 text-center">
                            <button
                              onClick={() => openDetailsModal(job)}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mx-1"
                            >
                              Details
                            </button>
                            {email === job.posted_by && (
                              <button
                                onClick={() => deleteJob(job.id)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      <ReactModal
        isOpen={detailsModalOpen}
        onRequestClose={() => setDetailsModalOpen(false)}
        contentLabel="Job Details"
        style={customStyles}
      >
        {selectedJob && (
          <div>
            <div className="flex justify-between w-full ">
              <p className=" w-full text-3xl font-bold">{selectedJob.title}</p>
              <div className="w-1/3 flex justify-end">
                <div className="flex flex-col items-end">
                  {!selectedJob.has_applied ? (
                    <button
                      onClick={() => openApplyModal(selectedJob)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-20 h-10 rounded"
                    >
                      Apply
                    </button>
                  ) : (
                    <button
                      disabled
                      className="bg-gray-500 text-white font-bold w-20 h-10 rounded"
                    >
                      Applied
                    </button>
                  )}

                  {selectedJob.user_id == profile.id && (
                    <button
                      onClick={() =>
                        (window.location.href = "/applicants/" + selectedJob.id)
                      }
                      className="bg-green-500 hover:bg-green-700 text-white font-bold w-40 h-10 rounded mt-2"
                    >
                      View Applicant
                    </button>
                  )}

                  {selectedJob.document_url && (
                    <a
                      href={selectedJob.document_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold w-40 h-10 rounded mt-2 flex justify-center items-center"
                    >
                      Download Docs
                    </a>
                  )}
                </div>
              </div>
            </div>

            <p className="text-gray-600">{selectedJob.company}</p>
            <p className="text-gray-600 font-bold">
              {selectedJob.applicant_count > 1 ? (
                <p>{selectedJob.applicant_count} applicants</p>
              ) : (
                <p>{selectedJob.applicant_count} applicant</p>
              )}
            </p>
            <div className="w-full h-0.5 w-full bg-gray-200 my-5"></div>
            <div className="lg:grid grid-cols-4 lg:gap-10 gap-2 flex flex-col">
              <div>
                <p>Posted By:</p>
                <div className="flex items-center">
                  <img
                    className="w-6 rounded-full"
                    src={selectedJob.profile_photo}
                  ></img>
                  <p className="ml-2 font-semibold">{selectedJob.name}</p>
                </div>
              </div>
              <div>
                <p>First Posted on:</p>
                <p className="font-semibold">
                  {new Date(selectedJob.created_at).toLocaleDateString()}
                </p>
              </div>
              <div>
                <p>Ideal Start Date:</p>
                <p className="font-semibold">
                  {new Date(selectedJob.ideal_start_date).toLocaleDateString()}
                </p>
              </div>
              <div>
                <p>Remote or Local:</p>
                <p className="font-semibold">{selectedJob.remote_or_local}</p>
              </div>
              <div>
                <p>Company Location:</p>
                <p className="font-semibold">{selectedJob.location}</p>
              </div>
              <div>
                <p>Hours per Week:</p>
                <p className="font-semibold">{selectedJob.hours_per_week}</p>
              </div>
              <div>
                <p>Job Categories:</p>
                <p className="font-semibold">{selectedJob.job_categories}</p>
              </div>
            </div>

            <div className="w-full mt-10">
              <p>{selectedJob.description}</p>
            </div>

            {/* Add more details as needed */}
            {/* <button
              onClick={() => setDetailsModalOpen(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button> */}
            <FontAwesomeIcon
              onClick={() => {
                setDetailsModalOpen(false);
              }}
              style={{ color: "red" }}
              className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
              icon={faXmark}
            />
          </div>
        )}
      </ReactModal>
      <ReactModal
        isOpen={applyModalOpen}
        onRequestClose={() => setApplyModalOpen(false)}
        style={customStyles}
        contentLabel="Apply for Job"
      >
        <form onSubmit={handleApply} className="space-y-4">
          <div>
            <label
              htmlFor="resume"
              className="block text-sm font-medium text-gray-700"
            >
              Resume
            </label>
            <input
              type="file"
              name="resume"
              onChange={(e) =>
                setApplicationData({
                  ...applicationData,
                  resume: e.target.files[0],
                })
              }
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-gray-700"
              required
            />
          </div>
          <div>
            <label
              htmlFor="coverLetter"
              className="block text-sm font-medium text-gray-700"
            >
              Cover Letter (Optional)
            </label>
            <input
              type="file"
              name="coverLetter"
              onChange={(e) =>
                setApplicationData({
                  ...applicationData,
                  coverLetter: e.target.files[0],
                })
              }
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-gray-700"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Submit Application"
              )}
            </button>
          </div>
        </form>
      </ReactModal>
    </div>
  );
};

export default Jobs;
