import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PostJobForm({ isOpen, onClose, profilePhoto, onJobPosted }) {
  const [jobDetails, setJobDetails] = useState({
    title: "",
    description: "",
    company: "",
    location: "",
    // school: "",
    ideal_start_date: "",
    remote_or_local: "remote", // defaulting to 'remote', adjust as needed
    hours_per_week: "",
    job_categories: "",
    profile_photo: profilePhoto,
    name: "",
  });

  const [document, setDocument] = useState(null);

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    linkedin: "",
    introduction: "",
    profilePhoto: "",
    profilePhotoUrl: "",
  });

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const handleChange = (e) => {
    setJobDetails({ ...jobDetails, [e.target.name]: e.target.value });
  };

  const handleDocumentChange = (e) => {
    setDocument(e.target.files[0]); // Update the state with the selected file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    const updatedJobDetails = {
      ...jobDetails,
      profile_photo: profile.profilePhoto,
      name: profile.firstName + " " + profile.lastName,
    };

    Object.keys(updatedJobDetails).forEach((key) =>
      formData.append(key, updatedJobDetails[key])
    );
    if (document) {
      formData.append("document_url", document); // Add the document to the FormData object
    }

    // const updatedJobDetails = { ...jobDetails, profile_photo: profilePhoto };
    try {
      const response = await axios.post(
        "https://hotelhive-backend.vercel.app/api/jobs",
        formData,
        {
          headers: {
            Email: sessionStorage.getItem("email"), // Example header, adjust as needed
          },
        }
      );
      toast.success(response.data.message); // Show success message
      onClose(); // Close the modal
      if (onJobPosted) {
        onJobPosted(); // Call the callback function passed as prop
      }
    } catch (error) {
      console.error("Failed to post job:", error.response.data);
      toast.error(error.response.data.error || "Failed to post job");
    }
  };
  const email = sessionStorage.getItem("email");
  useEffect(() => {
    axios
      .get("https://hotelhive-backend.vercel.app/api/profile/" + email)
      .then((res) => {
        setProfile({
          ...profile,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          introduction: res.data.introduction,
          linkedin: res.data.linkedin,
          profilePhoto: res.data.profilePhoto,
          profilePhotoUrl: res.data.profilePhotoUrl,
        });
      })
      .catch((error) => console.error("Error fetching profile data:", error));
  }, [email]);

  const customStyles = {
    content: {
      position: "absolute",
      top: isMobile ? "100%" : "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? "90%" : "80%", // Adjust based on your mobile responsiveness
      backgroundColor: "rgb(229 231 235)",
      padding: 40,
      borderRadius: "10px", // Optional for rounded corners
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Post Job Form"
      className="Modal"
      overlayClassName="Overlay"
      style={customStyles}
    >
      <form onSubmit={handleSubmit}>
        <div className="lg:flex justify-between">
          <div>
            {/* Title Field */}
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Title:
              </label>
              <input
                type="text"
                name="title"
                value={jobDetails.title}
                onChange={handleChange}
                required
                className="px-2 py-1 rounded-lg"
              />
            </div>

            {/* Additional Fields */}
            {/* Description */}
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Description:
              </label>
              <textarea
                name="description"
                value={jobDetails.description}
                onChange={handleChange}
                required
                className="px-2 py-1 rounded-lg "
                rows={4}
                cols={25}
              ></textarea>
            </div>
          </div>
          <div className="lg:mx-10">
            <div className="mb-4">
              <label
                htmlFor="location"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Location:
              </label>
              <input
                type="text"
                name="location"
                value={jobDetails.location}
                onChange={handleChange}
                required
                className="px-2 py-1 rounded-lg"
              />
            </div>
            {/* Ideal Start Date */}
            <div className="mb-4">
              <label
                htmlFor="ideal_start_date"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Ideal Start Date:
              </label>
              <input
                type="date"
                name="ideal_start_date"
                value={jobDetails.ideal_start_date}
                onChange={handleChange}
                className="px-2 py-1 rounded-lg"
              />
            </div>
            {/* Remote or Local */}
            <div className="mb-4">
              <label
                htmlFor="remote_or_local"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Remote or Local:
              </label>
              <select
                name="remote_or_local"
                value={jobDetails.remote_or_local}
                onChange={handleChange}
                className="px-2 py-1 rounded-lg"
              >
                <option value="remote">Remote</option>
                <option value="local">Local</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="document"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Upload Document:
              </label>
              <input
                type="file"
                name="document"
                onChange={handleDocumentChange}
                className="px-2 py-1 rounded-lg"
              />
            </div>
          </div>
          <div>
            {/* Hours Per Week */}
            <div className="mb-4">
              <label
                htmlFor="hours_per_week"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Hours Per Week:
              </label>
              <input
                type="number"
                name="hours_per_week"
                value={jobDetails.hours_per_week}
                onChange={handleChange}
                className="px-2 py-1 rounded-lg"
              />
            </div>

            {/* Job Categories */}
            <div className="mb-4">
              <label
                htmlFor="job_categories"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Job Categories:
              </label>
              <select
                name="job_categories"
                value={jobDetails.job_categories}
                onChange={handleChange}
                className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a category</option>
                <option value="Full-time">Full-time</option>
                <option value="Part-time">Part-time</option>
                <option value="Intern">Intern</option>
                <option value="Contract">Contract</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="company"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Company:
              </label>
              <input
                type="text"
                name="company"
                value={jobDetails.company}
                onChange={handleChange}
                required
                className="px-2 py-1 rounded-lg"
              />
            </div>
            {/* School */}
            {/* <div className="mb-4">
              <label
                htmlFor="school"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                School:
              </label>
              <input
                type="text"
                name="school"
                value={jobDetails.school}
                onChange={handleChange}
                className="px-2 py-1 rounded-lg"
              />
            </div> */}
          </div>
        </div>

        {/* Submit and Cancel Buttons */}
        <div className="flex items-center justify-between mt-4">
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Post Job
          </button>
        </div>
      </form>
      <FontAwesomeIcon
        onClick={onClose}
        style={{ color: "red" }}
        className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
        icon={faXmark}
      />
      <ToastContainer />
    </Modal>
  );
}

export default PostJobForm;
