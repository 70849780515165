import React from "react";

const CancelPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-100">
      <h1 className="text-3xl font-bold text-red-800">Payment Cancelled</h1>
      <p className="text-red-600">
        Your subscription process has been cancelled.
      </p>
      <a href="/subscriptions" className="mt-4 text-blue-600 hover:underline">
        Try again
      </a>
    </div>
  );
};

export default CancelPage;
