import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/homepage";
import { Main } from "./components/main";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PasswordReset } from "./components/passwordReset";
import PasswordResetRequest from "./components/passwordResetRequest";
import EditProfile from "./components/editProfile";
import ProfilePage from "./components/profile";
import Jobs from "./components/jobs";
import ApplicantList from "./components/applicantList";
import { Applicant } from "./components/applicant";
import FollowerFollowingPage from "./components/followersFollowing";
import Directories from "./components/directories";
import DealPage from "./components/deals";
import ChatApp from "./components/chat";
import NewsPage from "./components/news";
import ChangePasswordComponent from "./components/changePassword";
import SubscriptionPlans from "./components/subscriptions";
import SuccessPage from "./components/success";
import CancelPage from "./components/cancel";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/main" element={<Main />}></Route>
        <Route
          path="/password-reset/:token"
          element={<PasswordReset />}
        ></Route>
        <Route
          path="/password-request-reset"
          element={<PasswordResetRequest />}
        ></Route>
        {/* <Route path="/edit-profile" element={<EditProfile />}></Route> */}
        <Route path="/profile/:email" element={<ProfilePage />}></Route>
        <Route path="/jobs" element={<Jobs />}></Route>
        <Route path="/directories" element={<Directories />}></Route>
        <Route path="/deals" element={<DealPage />}></Route>
        <Route path="/chat" element={<ChatApp />}></Route>
        <Route path="/news" element={<NewsPage />}></Route>
        <Route path="/success" element={<SuccessPage />}></Route>
        <Route path="/cancel" element={<CancelPage />}></Route>
        <Route path="/subscriptions" element={<SubscriptionPlans />}></Route>
        <Route path="/settings" element={<ChangePasswordComponent />}></Route>
        <Route path="/applicants/:id" element={<Applicant />}></Route>
        <Route path="/followers/:email" element={<FollowerFollowingPage />} />
        <Route path="/followings/:email" element={<FollowerFollowingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
