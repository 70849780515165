import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export function PasswordReset() {
  const [password, setPassword] = useState("");
  const { token } = useParams(); // Assuming you're using react-router-dom

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`https://hotelhive-backend.vercel.app/reset-password/${token}`, {
        password,
      })
      .then((response) => {
        toast.success(response.data.message);
        // Consider redirecting the user to the sign-in page or displaying a success message/modal
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 px-4">
      <form onSubmit={handleSubmit} className="w-full max-w-xs">
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your new password"
          className="w-full px-3 py-2 mb-4 text-gray-700 border rounded-md focus:outline-none focus:shadow-outline"
          required
        />
        <button
          type="submit"
          className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          Reset Password
        </button>
      </form>
    </div>
  );
}
