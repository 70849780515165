import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faL,
  faMessage,
  faSpinner,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons"; // for the unliked state
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import ReactModal from "react-modal";
import { useMediaQuery } from "react-responsive";
function ProfilePage() {
  const emailSession = sessionStorage.getItem("email");
  const [profile, setProfile] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    linkedin: "",
    introduction: "",
    profilePhoto: "",
    profilePhotoUrl: "",
    followersCount: 0,
    followedCount: 0,
    country: "",
    company: "",
    school: "",
  });
  const { email } = useParams();
  const [isFollowing, setIsFollowing] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [showFollowers, setShowFollowers] = useState(false);
  const [showFollowings, setShowFollowings] = useState(false);
  const [searchTermFollowing, setSearchTermFollowing] = useState("");
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [showFollowingsModal, setShowFollowingsModal] = useState(false);
  const [followersSearchTerm, setFollowersSearchTerm] = useState("");
  const [followingsSearchTerm, setFollowingsSearchTerm] = useState("");

  const [postContent, setPostContent] = useState("");
  const [posts, setPosts] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [loadingReplies, setLoadingReplies] = useState({}); // Loading state keyed by post IDs

  const [isLoadingDeletePost, setIsLoadingDeletePost] = useState({});
  const [isLoadingDeleteReply, setIsLoadingDeleteReply] = useState({});

  const [profilePhotos, setProfilePhotos] = useState("");

  // Adjust setIsLoadingReply(true) and setIsLoadingReply(false) in handleReplySubmit
  // to update this state appropriately based on the post ID.

  const [replyContents, setReplyContents] = useState({});

  const [liked, setLiked] = useState(false);

  const openFollowersModal = () => {
    setShowFollowersModal(true);
  };

  const closeFollowersModal = () => {
    setShowFollowersModal(false);
  };

  const openFollowingsModal = () => {
    setShowFollowingsModal(true);
  };

  const closeFollowingsModal = () => {
    setShowFollowingsModal(false);
  };

  const checkIfFollowing = () => {
    axios
      .get(
        `https://hotelhive-backend.vercel.app/api/users/${emailSession}/is-following/${email}`
      )
      .then((response) => {
        setIsFollowing(response.data.isFollowing);
      })
      .catch((error) =>
        console.error("Error checking following status:", error)
      );
  };

  const deletePost = async (postId) => {
    setIsLoadingDeletePost((prev) => ({ ...prev, [postId]: true }));
    try {
      await axios.delete(
        `https://hotelhive-backend.vercel.app/posts/${postId}`,
        {
          headers: {
            Email: email,
          },
        }
      );
      setPosts(posts.filter((post) => post.id !== postId));
      toast.success("Post deleted successfully!");
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Failed to delete post.");
    } finally {
      setIsLoadingDeletePost((prev) => ({ ...prev, [postId]: false }));
    }
  };

  const deleteReply = async (replyId, postId) => {
    setIsLoadingDeleteReply((prev) => ({ ...prev, [replyId]: true }));
    try {
      await axios.delete(
        `https://hotelhive-backend.vercel.app/replies/${replyId}`,
        {
          headers: {
            Email: email,
          },
        }
      );
      const updatedPosts = posts.map((post) => {
        if (post.id === postId) {
          post.replies = post.replies.filter((reply) => reply.id !== replyId);
        }
        return post;
      });
      setPosts(updatedPosts);
      toast.success("Reply deleted successfully!");
    } catch (error) {
      console.error("Error deleting reply:", error);
      toast.error("Failed to delete reply.");
    } finally {
      setIsLoadingDeleteReply((prev) => ({ ...prev, [replyId]: false }));
    }
  };

  const handleLike = async (postId) => {
    try {
      const response = await axios.post(
        `https://hotelhive-backend.vercel.app/posts/${postId}/like`,
        { email: email } // Ensure you're sending the correct identifier for the user
      );

      // Update the posts state based on the like/unlike action
      setPosts(
        posts.map((post) => {
          if (post.id === postId) {
            return {
              ...post,
              likes: response.data.likes,
              userHasLiked: response.data.action === "liked" ? true : false,
            };
          }
          return post;
        })
      );

      toast.success(response.data.message);
    } catch (error) {
      console.error(
        "Failed to toggle the like status:",
        error.response?.data?.error || "An error occurred"
      );
      toast.error(error.response?.data?.error || "An error occurred");
    }
  };

  const handleReplySubmit = async (postId) => {
    const replyContent = replyContents[postId];
    if (!replyContent || !replyContent.trim()) {
      toast.warn("Please enter a reply.");
      return;
    }

    // Set loading state for the specific post
    setLoadingReplies((prevState) => ({ ...prevState, [postId]: true }));

    try {
      await axios.post(
        `https://hotelhive-backend.vercel.app/posts/${postId}/replies`,
        { content: replyContent, email: email }
      );
      setReplyContents("");
      toast.success("Reply added successfully!");
      // Optionally, fetch the updated list of posts/replies here
    } catch (error) {
      console.error("Error submitting reply:", error);
      toast.error("Failed to add reply.");
    } finally {
      // Reset loading state for the specific post
      setLoadingReplies((prevState) => ({ ...prevState, [postId]: false }));
    }
  };

  const fetchFollowers = () => {
    setShowFollowers(false);
    setShowFollowings(false);
    axios
      .get(`https://hotelhive-backend.vercel.app/api/users/${email}/followers`)
      .then((response) => {
        setFollowers(response.data);
        setShowFollowers(true); // Show the followers list
        console.log("followers", response.data);
      })
      .catch((error) => console.error("Error fetching followers:", error));
  };

  const fetchFollowings = () => {
    setShowFollowers(false);
    setShowFollowings(false);
    axios
      .get(`https://hotelhive-backend.vercel.app/api/users/${email}/followings`)
      .then((response) => {
        setFollowings(response.data);
        setShowFollowings(true); // Show the followings list
        console.log("following", response.data);
      })
      .catch((error) => console.error("Error fetching followings:", error));
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Make a GET request to fetch the posts
        const response = await axios.get(
          "https://hotelhive-backend.vercel.app/posts/" + email
        );
        // Update the posts state with the fetched posts
        setPosts(response.data);
        console.log("Posts fetched successfully:", response.data);
        // Fetch profile photos for each user ID
        const userIds = response.data.map((post) => post.user_id);
        await fetchProfilePhotos(userIds);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    // Ensure the user is authenticated before fetching posts
    if (!sessionStorage.getItem("email")) {
      window.location.href = "/"; // Redirect to the login page if not authenticated
    } else {
      fetchPosts(); // Fetch posts if the user is authenticated
    }
  }, []);

  useEffect(() => {
    fetchFollowers();

    fetchFollowings();
  }, []);

  useEffect(() => {
    // Fetch profile data...

    checkIfFollowing(); // Check if the current user is following the profile
  }, [email, emailSession]); // Depend on email and emailSession to re-check when they change

  useEffect(() => {
    // Function to fetch profile data
    const fetchProfileData = async () => {
      setIsLoadingPage(true); // Start loading
      try {
        const response = await axios.get(
          "https://hotelhive-backend.vercel.app/api/profile/" + email
        );
        const fetchedProfile = {
          userId: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          introduction: response.data.introduction,
          linkedin: response.data.linkedin,
          profilePhoto: response.data.profilePhoto,
          profilePhotoUrl: response.data.profilePhotoUrl,
          followersCount: response.data.followersCount,
          followedCount: response.data.followedCount,
          source: response.data.source,
          country: response.data.country,
          company: response.data.company,
          school: response.data.school,
          state: response.data.state,
          city: response.data.city,
        };
        setProfile(fetchedProfile);
        // Store the original profile data
        sessionStorage.setItem(
          "originalProfile",
          JSON.stringify(fetchedProfile)
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setIsLoadingPage(false); // End loading
      }
    };

    fetchProfileData();
  }, [email]); // Depend on email to re-fetch when it changes

  // Assuming this function is triggered when the "Cancel" button is clicked
  const handleCancelEdit = () => {
    // Retrieve the original profile data
    const originalProfile = JSON.parse(
      sessionStorage.getItem("originalProfile")
    );
    // Reset the profile state to the original data
    setProfile(originalProfile);
    setIsEditMode(false); // Exit edit mode
  };

  // Function to follow a user
  const followUser = (email) => {
    axios
      .post(`https://hotelhive-backend.vercel.app/api/users/${email}/follow`, {
        email: emailSession,
      })
      .then((response) => {
        console.log(response.data.message);
        setIsFollowing(true);
      });
  };

  // Function to unfollow a user
  const unfollowUser = (email) => {
    axios
      .post(
        `https://hotelhive-backend.vercel.app/api/users/${email}/unfollow`,
        {
          email: emailSession,
        }
      )
      .then((response) => {
        console.log(response.data.message);
        setIsFollowing(false);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Update state when a file is selected
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleProfileUpdate = (e) => {
    e.preventDefault(); // Prevent the form from causing a page reload
    const formData = new FormData();

    // Append profile data to formData
    Object.keys(profile).forEach((key) => {
      if (
        key !== "profilePhotoUrl" &&
        key !== "followersCount" &&
        key !== "followedCount"
      ) {
        // Exclude URL and counts
        formData.append(key, profile[key]);
      }
    });

    // Append profile photo if it has been changed
    if (selectedFile) {
      formData.append("profilePhoto", selectedFile);
    }

    axios
      .post(
        `https://hotelhive-backend.vercel.app/api/update-profile/${email}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setIsEditMode(false); // Exit edit mode on success
        // Optionally, refresh profile data here
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        // Handle error
      });
  };

  const HandleImageClick = (user_id) => {
    axios
      .get("https://hotelhive-backend.vercel.app/users/" + user_id)
      .then((res) => {
        window.location.href = "/profile/" + res.data.email;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to fetch profile photos based on user IDs
  const fetchProfilePhotos = async (userIds) => {
    try {
      const responses = await Promise.all(
        userIds.map(async (userId) => {
          const response = await axios.get(
            `https://hotelhive-backend.vercel.app/users/${userId}`
          );
          return response.data;
        })
      );

      const profilePhotosMap = responses.reduce((map, user) => {
        map[user.id] = user.profile_photo;
        return map;
      }, {});

      setProfilePhotos(profilePhotosMap);
    } catch (error) {
      console.error("Error fetching profile photos:", error);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const customStyles = {
    content: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
      width: isMobile ? "80%" : "40%",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 50,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
  return (
    <>
      <Navbar className="" />
      <div className="w-full bg-gray-100 min-h-screen flex flex-col items-center">
        <div className="bg-[#2544E3] absolute h-40 w-full"></div>
        <div className="container mx-auto w-full p-5 bg-white rounded-xl relative mt-10 shadow-lg ">
          <div className="flex items-center text-center flex-col lg:flex-row">
            <div className="lg:w-1/4 ml-10 m-5">
              {isEditMode ? (
                <input
                  type="file"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  className="mb-4 block w-full"
                />
              ) : profile.profilePhoto ? (
                <img
                  src={profile.profilePhoto}
                  alt="Profile"
                  className="mb-4 rounded-full w-40 h-40 object-cover mx-auto"
                />
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={faUser}
                    className="mb-4 rounded-full w-40 h-40 object-cover mx-auto cursor-pointer"
                  />
                </div>
              )}

              <div className="text-center lg:text-left mt-4 lg:mt-0 flex justify-between mb-4 lg:mx-2">
                <div>
                  <p
                    onClick={fetchFollowers}
                    className="text-xl cursor-pointer"
                    onClick={openFollowersModal}
                  >
                    <strong>{profile.followersCount}</strong> followers
                  </p>
                  {/* <Link
                    className="text-blue-500 underline"
                    to={`/followers/${email}`}
                  >
                    See Followers
                  </Link> */}
                  {/* <button
                    className="text-sm bg-blue-500 hover:bg-blue-700 w-full text-white rounded-lg px-2 py-1"
                    onClick={fetchFollowers}
                  >
                    Show
                  </button> */}
                </div>
                <div className="lg:ml-4">
                  <p
                    onClick={fetchFollowings}
                    className="text-xl cursor-pointer"
                    onClick={openFollowingsModal}
                  >
                    <strong>{profile.followedCount}</strong> following
                  </p>
                  {/* <Link
                    className="text-blue-500 underline"
                    to={`/followings/${email}`}
                  >
                    See Followings
                  </Link> */}
                  {/* <button
                    className="text-sm bg-blue-500 hover:bg-blue-700 w-full text-white rounded-lg px-2 py-1"
                    onClick={fetchFollowings}
                  >
                    Show
                  </button> */}
                </div>
              </div>
              {emailSession != email && (
                <div className="flex items-center w-full">
                  {isFollowing ? (
                    <button
                      className="bg-gray-500 hover:bg-gray-700 w-full text-white rounded-lg py-2"
                      onClick={() => unfollowUser(email)}
                    >
                      Followed
                    </button>
                  ) : (
                    <button
                      className="bg-blue-500 hover:bg-blue-700 w-full text-white rounded-lg py-2"
                      onClick={() => followUser(email)}
                    >
                      Follow
                    </button>
                  )}
                  <button
                    onClick={() => {
                      // Retrieve the existing selected users array from localStorage
                      let selectedUsers =
                        JSON.parse(localStorage.getItem("selectedUsers")) || [];

                      // Check if the user ID already exists in the array
                      if (
                        !selectedUsers.some((user) => user === profile.userId)
                      ) {
                        // Add the current selected user to the array
                        selectedUsers.push(profile.userId);

                        // Save the updated array back to localStorage
                        localStorage.setItem(
                          "selectedUsers",
                          JSON.stringify(selectedUsers)
                        );
                      } else {
                        // Optionally, display a message or handle the case where the user is already selected
                        console.log("User is already selected");
                      }
                      localStorage.setItem(
                        "selectedUser",
                        JSON.stringify(profile)
                      );
                      localStorage.setItem("fromProfile", true);
                      // Redirect to the chat page
                      window.location.href = "/chat";
                    }}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 ml-2"
                  >
                    <FontAwesomeIcon icon={faMessage} />
                  </button>
                </div>
              )}
            </div>
            {isLoadingPage ? (
              <div className="flex justify-center items-center">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              </div>
            ) : (
              <div className="flex items-center justify-between flex-col lg:flex-row w-full text-left border border-gray-200 p-10 rounded-lg">
                <div className="lg:w-3/4">
                  {" "}
                  {isEditMode ? (
                    <>
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={profile.firstName}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                        />
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={profile.lastName}
                          onChange={handleInputChange}
                          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                        />
                      </div>

                      <input
                        type="text"
                        name="country"
                        placeholder="Country"
                        value={profile.country}
                        onChange={(e) => {
                          setProfile({
                            ...profile,
                            country: e.target.value,
                          });
                        }}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                      />

                      {/* Add state input */}
                      <input
                        type="text"
                        name="state"
                        placeholder="State"
                        value={profile.state}
                        onChange={(e) => {
                          setProfile({
                            ...profile,
                            state: e.target.value,
                          });
                        }}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                      />
                      {/* Add city input */}
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={profile.city}
                        onChange={(e) => {
                          setProfile({
                            ...profile,
                            city: e.target.value,
                          });
                        }}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                      />
                    </>
                  ) : (
                    <h1 className="text-3xl font-semibold">
                      {profile.firstName} {profile.lastName} |{" "}
                      <span className="text-lg text-gray-600 italic">
                        ({profile.country}, {profile.state}, {profile.city})
                      </span>
                    </h1>
                  )}
    
                  {isEditMode ? (
                    <textarea
                      name="introduction"
                      placeholder="Introduction"
                      value={profile.introduction}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                    />
                  ) : (
                    <p className="mt-4">{profile.introduction}</p>
                  )}
                </div>
                <div className="lg:ml-4 flex items-center lg:w-1/4 lg:h-40 relative w-full">
                  <div className="w-1 h-full bg-gray-200 hidden lg:block"></div>
                  <div className="lg:ml-10 text-left">
                    <p className="text-2xl font-semibold mb-2">Company</p>
                    {isEditMode ? (
                      <input
                        type="text"
                        name="company"
                        placeholder="Company"
                        value={profile.company}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                      />
                    ) : (
                      <p className="text-gray-600">
                        {profile.company ? profile.company : <span>None</span>}
                      </p>
                    )}
                    <p className="text-2xl font-semibold mb-2 mt-2">School</p>
                    {isEditMode ? (
                      <input
                        type="text"
                        name="school"
                        placeholder="School"
                        value={profile.school}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm border px-2 py-1"
                      />
                    ) : (
                      <p className="text-gray-600">
                        {profile.school ? profile.school : <span>None</span>}
                      </p>
                    )}
                    {/* <p className="text-gray-600">You are in Trial Account</p> */}
                    {email == emailSession &&
                      (isEditMode ? (
                        <div>
                          <button
                            onClick={handleProfileUpdate} // This should be your function to update the profile
                            className="px-4 py-2 bg-green-500 text-white rounded-md lg:absolute -top-8 right-13"
                          >
                            Save Changes
                          </button>
                          <button
                            onClick={handleCancelEdit}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md lg:absolute -top-8 -right-8"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => setIsEditMode(true)}
                          className="px-4 py-2 bg-blue-500 text-white rounded-md lg:absolute -top-5 -right-5"
                        >
                          Edit
                        </button>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <ReactModal
          isOpen={showFollowersModal}
          onRequestClose={closeFollowersModal}
          contentLabel="Followers Modal"
          style={customStyles}
        >
          {/* Render the list of followers inside this modal */}
          <div className="w-full flex flex-col items-center">
            <h2 className="text-xl font-semibold mb-4">
              {profile.firstName}'s followers
            </h2>
            <input
              type="text"
              placeholder="Search followers..."
              value={followersSearchTerm}
              onChange={(e) => setFollowersSearchTerm(e.target.value)}
              className="border border-gray-300 rounded-md px-3 py-2 mb-4"
            />
            <ul className="w-full">
              {followers
                .filter(
                  (follower) =>
                    follower.firstName
                      .toLowerCase()
                      .includes(followersSearchTerm.toLowerCase()) ||
                    follower.lastName
                      .toLowerCase()
                      .includes(followersSearchTerm.toLowerCase()) ||
                    follower.email
                      .toLowerCase()
                      .includes(followersSearchTerm.toLowerCase())
                )
                .map((follower, index, followersArray) => (
                  <li
                    onClick={() => {
                      window.location.href = "/profile/" + follower.email;
                    }}
                    key={follower.id}
                    className={`flex items-start cursor-pointer px-4 py-4 w-full ${
                      index === 0 ? "border-t-2" : "border-t"
                    } ${
                      index === followersArray.length - 1
                        ? "border-b-2"
                        : "border-b"
                    } border-gray-300`}
                  >
                    {follower.profilePhoto ? (
                      <img
                        src={follower.profilePhoto}
                        alt="Profile"
                        className="rounded-full w-8 h-8 object-cover mr-2"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faUser}
                        className="rounded-full w-8 h-8 object-cover mr-2 cursor-pointer"
                      />
                    )}
                    <div className="w-full ml-10">
                      <p className="font-bold">
                        {follower.firstName} {follower.lastName}
                      </p>
                      {follower.company ? (
                        <p>{follower.company}</p>
                      ) : (
                        <p>No company information available</p>
                      )}

                      {follower.school ? (
                        <p className="text-gray-600">{follower.school}</p>
                      ) : (
                        <p className="text-gray-600">
                          No school information available
                        </p>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <FontAwesomeIcon
            onClick={closeFollowersModal}
            style={{ color: "red" }}
            className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
            icon={faXmark}
          />
          <div></div>
        </ReactModal>

        <ReactModal
          isOpen={showFollowingsModal}
          onRequestClose={closeFollowingsModal}
          contentLabel="Followings Modal"
          style={customStyles}
        >
          {/* Render the list of followings inside this modal */}
          <div className="w-full flex flex-col items-center">
            <h2 className="text-xl font-semibold mb-4">
              {profile.firstName}'s following
            </h2>
            <input
              type="text"
              placeholder="Search followings..."
              value={followingsSearchTerm}
              onChange={(e) => setFollowingsSearchTerm(e.target.value)}
              className="border border-gray-300 rounded-md px-3 py-2 mb-4"
            />
            <ul className="w-full">
              {followings
                .filter(
                  (following) =>
                    following.firstName
                      .toLowerCase()
                      .includes(followingsSearchTerm.toLowerCase()) ||
                    following.lastName
                      .toLowerCase()
                      .includes(followingsSearchTerm.toLowerCase()) ||
                    following.email
                      .toLowerCase()
                      .includes(followingsSearchTerm.toLowerCase())
                )
                .map((following, index, followersArray) => (
                  <li
                    onClick={() => {
                      window.location.href = "/profile/" + following.email;
                    }}
                    key={following.id}
                    className={`flex items-start cursor-pointer px-4 py-4 w-full ${
                      index === 0 ? "border-t-2" : "border-t"
                    } ${
                      index === followersArray.length - 1
                        ? "border-b-2"
                        : "border-b"
                    } border-gray-300`}
                  >
                    {following.profilePhoto ? (
                      <img
                        src={following.profilePhoto}
                        alt="Profile"
                        className="rounded-full w-8 h-8 object-cover mr-2"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faUser}
                        className="rounded-full w-8 h-8 object-cover mr-2 cursor-pointer"
                      />
                    )}
                    <div className="w-full ml-10">
                      <p className="font-bold">
                        {following.firstName} {following.lastName}
                      </p>
                      {following.company ? (
                        <p>{following.company}</p>
                      ) : (
                        <p>No company information available</p>
                      )}

                      {following.school ? (
                        <p className="text-gray-600">{following.school}</p>
                      ) : (
                        <p className="text-gray-600">
                          No school information available
                        </p>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <FontAwesomeIcon
            onClick={closeFollowingsModal}
            style={{ color: "red" }}
            className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
            icon={faXmark}
          />
        </ReactModal>

        {/* Feed Section */}
        <div className="w-10/12 mt-10">
          {posts.length < 1 ? (
            <p>There is no posts yet</p>
          ) : (
            posts.map((post) => (
              <div
                key={post.id}
                className="bg-white shadow rounded-lg p-4 mb-8 relative"
              >
                <div
                  onClick={() => {
                    HandleImageClick(post.user_id);
                  }}
                  className="flex items-center cursor-pointer"
                >
                  {profilePhotos[post.user_id] ? (
                    <img
                      src={profilePhotos[post.user_id]}
                      className="mr-4 w-14 rounded-full"
                    ></img>
                  ) : (
                    <FontAwesomeIcon
                      icon={faUser}
                      className="mr-4 w-8 h-8 rounded-full"
                    />
                  )}

                  <div>
                    <div className="flex items-center">
                      <h3 className="font-bold">
                        {post.firstName} {post.lastName}
                      </h3>
                      {/* <p className="ml-3 bg-blue-500 px-2 py-1 rounded-lg text-white">
                        {post.source}
                      </p> */}
                    </div>

                    {post.company ? (
                      <p>{post.company}</p>
                    ) : (
                      <p>No company information available</p>
                    )}

                    {post.school ? (
                      <p className="text-gray-600">{post.school}</p>
                    ) : (
                      <p className="text-gray-600">
                        No school information available
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full h-0.5 bg-gray-200 mt-5"></div>
                <p className="text-gray-800 p-4">{post.content}</p>

                <div className="bg-gray-100 rounded-lg">
                  <div className="w-full flex justify-start items-center p-2 text-gray-600">
                    <button
                      className="text-sm "
                      onClick={() => handleLike(post.id)}
                    >
                      <FontAwesomeIcon
                        icon={post.userHasLiked ? fasHeart : farHeart}
                        style={{ color: post.userHasLiked ? "pink" : "grey" }}
                      />
                    </button>
                    <p className="ml-2">{post.likes} likes</p>
                  </div>

                  <div>
                    {/* Replies Section */}
                    {post.replies.length > 0 ? (
                      post.replies.map((reply) => (
                        <div
                          key={reply.id}
                          className="mb-2 flex items-center space-x-2 ml-5 cursor-pointer"
                          onClick={() => {
                            HandleImageClick(reply.user_id);
                          }}
                        >
                          {reply.userProfilePhoto && (
                            <img
                              src={reply.userProfilePhoto}
                              alt={`${reply.userName}'s profile`}
                              className="w-4 h-4 rounded-full"
                            />
                          )}
                          <div className="flex items-center justify-between w-full">
                            <p>
                              <strong>{reply.userName}:</strong>{" "}
                              <span className="text-gray-800">
                                {reply.content}
                              </span>
                            </p>
                            {reply.user_id == profile.userId && (
                              <button
                                onClick={() => deleteReply(reply.id, post.id)}
                                disabled={isLoadingDeleteReply[reply.id]}
                                className="text-sm text-red-500 hover:text-red-600 mr-3"
                              >
                                {isLoadingDeleteReply[reply.id] ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  "Delete this reply"
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>
                        <p className="text-center text-gray-600">
                          No comment at the moment
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-between mt-3">
                    <img
                      src={profile.profilePhoto}
                      className="mr-4 w-8 rounded-full"
                    ></img>
                    {/* Reply Form */}
                    <textarea
                      className="w-full border border-gray-300 rounded-lg p-2"
                      rows={1}
                      placeholder="Write a reply..."
                      value={replyContents[post.id] || ""} // Use the post's ID to get its specific reply content
                      onChange={(e) =>
                        setReplyContents({
                          ...replyContents,
                          [post.id]: e.target.value,
                        })
                      }
                    ></textarea>
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow"
                      onClick={() => handleReplySubmit(post.id)}
                      disabled={loadingReplies[post.id]} // Use the loading state specific to this post
                    >
                      {loadingReplies[post.id] ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Reply"
                      )}
                    </button>
                  </div>

                  <div className="flex justify-between">
                    <button
                      onClick={() => deletePost(post.id)}
                      disabled={isLoadingDeletePost[post.id]}
                      className="mt-2 px-4 py-2 text-red-500 rounded-lg absolute top-1 right-4"
                    >
                      {isLoadingDeletePost[post.id] ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Delete"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {/* <div className="w-2/3 border bg-white mt-60 flex justify-between p-4 shadow-md rounded-xl "> */}
        {/* {!showFollowers && !showFollowings && (
            <div>
              <p className="text-green-600">
                Click 'followers' or 'following' below the profile photo to see
                the list displayed here!
              </p>
            </div>
          )} */}
        {/* <div className="w-full flex flex-col items-center">
            <h3>Followers</h3>
            <div className="w-full flex flex-col items-center mb-4">
              <input
                type="text"
                placeholder="Search followers..."
                value={followersSearchTerm}
                onChange={(e) =>
                  setFollowersSearchTerm(e.target.value.toLowerCase())
                }
                className="px-2 py-1 border border-gray-100 rounded-md shadow-sm w-1/2"
              />
            </div>

            <ul>
              {followers
                .filter(
                  (follower) =>
                    follower.firstName
                      .toLowerCase()
                      .includes(followersSearchTerm) ||
                    follower.lastName
                      .toLowerCase()
                      .includes(followersSearchTerm) ||
                    follower.email.toLowerCase().includes(followersSearchTerm)
                )
                .map((follower) => (
                  <div
                    onClick={() => {
                      window.location.href = "/profile/" + follower.email;
                    }}
                    className="flex items-center mb-4 cursor-pointer px-2 py-1 shadow border border-gray-100 rounded-lg"
                    key={follower.id}
                  >
                    <img
                      src={follower.profilePhoto}
                      alt="Profile"
                      className="rounded-full w-8 h-8 object-cover mr-4"
                    />
                    {follower.firstName} {follower.lastName}
                  </div>
                ))}
            </ul>
          </div>

          <div className="w-full flex flex-col items-center">
            <h3>Followings</h3>
            <div className="w-full flex flex-col items-center mb-4">
              <input
                type="text"
                placeholder="Search followings..."
                value={searchTermFollowing}
                onChange={(e) =>
                  setSearchTermFollowing(e.target.value.toLowerCase())
                }
                className="px-2 py-1 border border-gray-100 rounded-md shadow-sm w-1/2"
              />
            </div>

            <ul>
              {followings
                .filter(
                  (following) =>
                    following.firstName
                      .toLowerCase()
                      .includes(searchTermFollowing) ||
                    following.lastName
                      .toLowerCase()
                      .includes(searchTermFollowing) ||
                    following.email.toLowerCase().includes(searchTermFollowing)
                )
                .map((following) => (
                  <div
                    onClick={() => {
                      window.location.href = "/profile/" + following.email;
                    }}
                    className="flex items-center mb-4 cursor-pointer px-2 py-1 shadow border border-gray-100 rounded-lg"
                    key={following.id}
                  >
                    <img
                      src={following.profilePhoto}
                      alt="Profile"
                      className="rounded-full w-8 h-8 object-cover mr-4"
                    />
                    {following.firstName} {following.lastName}
                  </div>
                ))}
            </ul>
          </div> */}
        {/* </div> */}
        <ToastContainer />
      </div>
    </>
  );
}

export default ProfilePage;
