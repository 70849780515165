import React, { useState } from "react";
import Modal from "react-modal";
import "../App.css";
import { useMediaQuery } from "react-responsive";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const [isAccessModalOpen, setAccessModalOpen] = useState(false);
  const [isSignInModalOpen, setSignInModalOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  // At the beginning of your Home component
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");

  const [selectedOption, setSelectedOption] = useState("Select Option");
  const [selectedMBA, setSelectedMBA] = useState("Select MBA Program");
  const [selectedCountry, setSelectedCountry] = useState("Select Country");
  const [selectedSource, setSelectedSource] = useState("Select Option");

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const recaptchaRef = React.createRef();

  const onReCAPTCHAChange = (captchaValue) => {
    if (captchaValue) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  const mbaPrograms = [
    "Stanford Graduate School of Business",
    "Harvard Business School",
    "University of Pennsylvania: Wharton",
    "London Business School",
    "MIT: Sloan",
    "INSEAD",
    "Columbia Business School",
    "IE Business School",
    "University of Chicago: Booth",
    "HEC Paris",
    "Other",
  ];

  const options2 = [
    "A Friend",
    "Social Media",
    "Online Advertising",
    "News Article",
    "Other",
  ];

  const options = [
    "Investing",
    "Selling",
    "Currently Operating a Property",
    "A Broker",
    "A Lender",
    "Seeking Employment",
    "Offering Professional Services",
  ];

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, North",
    "Korea, South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const customStyles = {
    content: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
      width: isMobile ? "80%" : "40%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const customStyles2 = {
    content: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
      width: isMobile ? "80%" : "40%",
      height: isMobile ? "45%" : "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const customStyles3 = {
    content: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
      width: isMobile ? "80%" : "40%",
      height: isMobile ? "45%" : "40%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 50,
    },
  };

  // Function to handle option selection
  const handleSelectOption = (option, type) => {
    switch (type) {
      case "option":
        setSelectedOption(option);
        setIsOpen(false);
        break;
      case "mba":
        setSelectedMBA(option);
        setIsOpen4(false);
        break;
      case "country":
        setSelectedCountry(option);
        setSearchTerm("");
        setIsOpen2(false);
        break;
      case "source":
        setSelectedSource(option);
        setIsOpen3(false);
        break;
      default:
      // handle default case
    }
  };

  const isFormFilled = () => {
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      // linkedin.trim() !== "" &&
      password.trim() !== "" &&
      isVerified // Assuming isVerified is `true` when reCAPTCHA is completed
    );
  };

  const handleSignIn = (event) => {
    event.preventDefault();

    if (!signInEmail || !signInPassword) {
      toast.error("Please enter both email and password.");
      return;
    }

    const userData = {
      email: signInEmail,
      password: signInPassword,
    };

    axios
      .post("https://hotelhive-backend.vercel.app/sign-in", userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*", // Accept all content types
          "Access-Control-Allow-Origin": "*", // Allow all origins (not recommended)
        },
      })
      .then((response) => {
        console.log("Success:", response.data.message);
        setIsLoading2(false);
        if (response.data.message === "Login successful.") {
          sessionStorage.setItem("email", signInEmail);
          window.location.href = "/main";
        }
      })
      .catch((error) => {
        setIsLoading2(false);
        console.error(
          "Error:",
          error.response ? error.response.data : "An error occurred"
        );
        toast.error(
          error.response
            ? error.response.data.error
            : "An error occurred during sign in."
        );
      });
  };

  function sendDataToFlask(event) {
    event.preventDefault();

    if (!isVerified) {
      toast.error("Please complete the reCAPTCHA");
      return;
    }

    const data = {
      selectedOption,
      selectedMBA,
      selectedCountry,
      selectedSource,
      firstName,
      lastName,
      email,
      password,
      // linkedin,
      introduction,
      selectedCity,
      selectedState,
    };

    axios
      .post("https://hotelhive-backend.vercel.app/submit-get-access", data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*", // Accept all content types
          "Access-Control-Allow-Origin": "*", // Allow all origins (not recommended)
        },
      })
      .then((response) => {
        toast.success("Success:", response.data.message);
        console.log(response.data.message);
        setAccessModalOpen(false);
        setIsSuccessModalOpen(true); // Open the success modal
        setIsLoading(false);
        // setTimeout(() => {
        //   setIsSuccessModalOpen(false); // Optionally close the modal after a delay
        //   window.location.href = "/";
        // }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(
          "Error:",
          error.response ? error.response.data : "An error occurred"
        );
        toast.error(
          error.response
            ? error.response.data.error
            : "An error occurred during sign in."
        );
        // setTimeout(() => {
        //   window.location.href = "/";
        // }, 1000);
      });
  }

  return (
    <div className="flex h-screen">
      {/* <div className="w-full hidden lg:flex flex-col justify-center items-center text-3xl font-bold text-white bg-[#2544E3]">
        <p>Connect with explorers</p>
        <p className="mt-4">Discover financiers</p>
        <p className="mt-4">Explore sectors</p>
        <p className="mt-4">Market your company</p>
      </div> */}
      <div className="w-full bg-[#2544E3] flex flex-col justify-center items-center text-2xl text-white font-bold text-center p-6">
        <div className="flex items-center flex-col">
          {/* <p className="">
            "A comprehensive network diligently focused on the strategic
            acquisition and dynamic sale of successful enterprises."
          </p>
          <p className="mt-4 w-10/12">
            "Our mission is to connect ambitious investors with thriving
            companies, fostering opportunities for growth, innovation, and
            financial success."
          </p> */}
          <p className=" text-white text-4xl ">JOIN HOTELHIVE NOW</p>
          <div className="flex items-center my-4 flex-col lg:flex-row">
            <button
              className="w-64 rounded-lg hover:opacity-80 py-2 text-[22px] text-[#2544E3] bg-white border-2 border-white"
              onClick={() => setAccessModalOpen(true)}
            >
              GET ACCESS
            </button>

            <Modal
              isOpen={isSuccessModalOpen}
              onRequestClose={() => setIsSuccessModalOpen(false)}
              style={customStyles3} // You can reuse the customStyles or define a new one
              contentLabel="Success Modal"
            >
              <div className="flex flex-col items-center justify-center p-4">
                <h2 className="text-2xl font-semibold mb-4">
                  Sign Up Successful!
                </h2>
                <p>
                  Please check your email inbox and spam folder for a
                  confirmation email.
                </p>
                <button
                  className="bg-[#2544E3] mx-auto block mt-6 hover:opacity-80 py-2 px-12 rounded-lg text-white border-2 border-white"
                  onClick={() => setIsSuccessModalOpen(false)}
                >
                  Close
                </button>
              </div>
              <FontAwesomeIcon
                onClick={() => setIsSuccessModalOpen(false)}
                style={{ color: "red" }}
                className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
                icon={faXmark}
              />
            </Modal>

            <div className="flex justify-center items-center">
              <Modal
                isOpen={isAccessModalOpen}
                onRequestClose={() => setAccessModalOpen(false)}
                style={customStyles}
                // className="modal-animation"
                contentLabel="Get Access Modal"
              >
                <form onSubmit={sendDataToFlask}>
                  <div className="w-full ">
                    <p className="mb-3 text-center text-2xl">I am ...</p>
                    <div className="relative inline-block text-left w-full">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                        id="menu-button"
                        aria-expanded="true"
                        aria-haspopup="true"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {selectedOption}
                        <svg
                          className="-mr-1 ml-2 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {isOpen && (
                        <div
                          className="z-50 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                        >
                          <div className="py-1" role="none">
                            {options.map((option, index) => (
                              <button
                                key={option}
                                className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                onClick={() =>
                                  handleSelectOption(option, "option")
                                }
                              >
                                {option}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <p className="mt-3 text-center text-2xl mb-3">
                      About Me ...
                    </p>
                    <div className="w-full flex justify-between">
                      <input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="px-4 py-2 border-gray-300 border rounded-lg w-full"
                        placeholder="firstname"
                        required
                      ></input>
                      <input
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="px-4 py-2 border-gray-300 border rounded-lg w-full ml-2"
                        placeholder="lastname"
                        required
                      ></input>
                    </div>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="px-4 py-2 border-gray-300 border rounded-lg w-full mt-3 "
                      placeholder="email"
                      required
                    ></input>
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="px-4 py-2 border-gray-300 border rounded-lg w-full mt-3 "
                      placeholder="password"
                      required
                    ></input>
                    {/* <input
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                      className="px-4 py-2 border-gray-300 border rounded-lg w-full mt-3 mb-3"
                      placeholder="linkedin"
                      required
                    ></input> */}
                    {/* <div className="relative inline-block text-left w-full mb-3">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                        id="menu-button"
                        aria-expanded={isOpen4}
                        aria-haspopup="true"
                        onClick={() => setIsOpen4(!isOpen4)}
                      >
                        {selectedMBA}
                        <svg
                          className="-mr-1 ml-2 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {isOpen4 && (
                        <div
                          className="z-50 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                        >
                          <div className="py-1" role="none">
                            {mbaPrograms.map((program, index) => (
                              <button
                                key={program}
                                className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                onClick={() =>
                                  handleSelectOption(program, "mba")
                                }
                              >
                                {program}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div> */}
                    <div className="relative inline-block text-left w-full mt-3">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                        id="menu-button"
                        aria-expanded={isOpen2}
                        aria-haspopup="true"
                        onClick={() => setIsOpen2(!isOpen2)}
                      >
                        {selectedCountry}
                        <svg
                          className="-mr-1 ml-2 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {isOpen2 && (
                        <div
                          className="z-50 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="menu-button"
                          tabIndex="-1"
                        >
                          <div className="py-1" role="none">
                            <input
                              type="text"
                              placeholder="Search country..."
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              className="w-full px-4 py-2 text-sm text-gray-700 border-none focus:outline-none focus:ring-0"
                            />
                            {countries
                              .filter((country) =>
                                country
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                              )
                              .map((country, index) => (
                                <button
                                  key={country}
                                  className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                                  onClick={() =>
                                    handleSelectOption(country, "country")
                                  }
                                >
                                  {country}
                                </button>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        className="px-4 py-2 border-gray-300 border rounded-lg w-full mt-3"
                        placeholder="City"
                        required
                      ></input>
                      <input
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        className="px-4 py-2 border-gray-300 border rounded-lg w-full mt-3"
                        placeholder="State"
                        required
                      ></input>
                    </div>
                    <textarea
                      value={introduction}
                      onChange={(e) => setIntroduction(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mt-3"
                      rows="4"
                      placeholder="Introduce yourself(optional)"
                    ></textarea>
                    {/*<p className="mb-3 text-center text-2xl">
                      I first heard about HotelHive from ...
                    </p>*/}

                  </div>
                  <div className="flex w-full justify-center flex-col items-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LesdmApAAAAAFM6ws3BOt_PAxHrHXPfjdAiiMnW"
                      onChange={onReCAPTCHAChange}
                      className="mt-3"
                    />
                    <button
                      className="bg-[#2544E3] hover:opacity-80 py-2 px-12 rounded-lg mt-4 mx-auto text-white  border-2 border-white"
                      onClick={() => {
                        setIsLoading(true);
                        // simulate an API call
                        // setTimeout(() => {
                        //   setAccessModalOpen(true);
                        //   setIsLoading(false);
                        // }, 2000); // simulate loading time
                      }}
                      disabled={isLoading}
                      disabled={!isFormFilled()}
                    >
                      {isLoading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </div>
                </form>
                <FontAwesomeIcon
                  onClick={() => setAccessModalOpen(false)}
                  style={{ color: "red" }}
                  className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
                  icon={faXmark}
                />
              </Modal>
            </div>
            <button
              className="w-64 rounded-lg hover:opacity-80 py-2 text-[22px] text-white  border-2 border-white lg:ml-4 mt-4 lg:mt-0 "
              onClick={() => setSignInModalOpen(true)}
            >
              SIGN IN
            </button>

            <Modal
              isOpen={isSignInModalOpen}
              onRequestClose={() => setSignInModalOpen(false)}
              style={customStyles2}
              contentLabel="Sign In Modal"
            >
              <div className="w-full flex flex-col items-center">
                <h2 className="text-2xl font-bold mb-4">Sign In</h2>
                <form onSubmit={handleSignIn}>
                  <input
                    type="email"
                    value={signInEmail}
                    onChange={(e) => setSignInEmail(e.target.value)}
                    className="px-4 py-2 border-gray-300 border rounded-lg w-full mb-3"
                    placeholder="Email"
                    required
                  />
                  <input
                    type="password"
                    value={signInPassword}
                    onChange={(e) => setSignInPassword(e.target.value)}
                    className="px-4 py-2 border-gray-300 border rounded-lg w-full mb-3"
                    placeholder="Password"
                    required
                  />{" "}
                  <p className="mb-4 text-right">
                    Forgot your password?{" "}
                    <a href="/password-request-reset" className="text-blue-600">
                      Reset here
                    </a>
                  </p>
                  <button
                    onClick={() => {
                      setIsLoading2(true);
                    }}
                    type="submit"
                    className="bg-[#2544E3] mx-auto block hover:opacity-80 py-2 px-12 rounded-lg text-white border-2 border-white"
                  >
                    {isLoading2 ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Sign In"
                    )}
                  </button>
                  {/* <p>
                    Don't have an account?{" "}
                    <a href="/register" className="text-blue-600">
                      Register
                    </a>
                  </p> */}
                </form>
              </div>
              <FontAwesomeIcon
                onClick={() => setSignInModalOpen(false)}
                style={{ color: "red" }}
                className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
                icon={faXmark}
              />
            </Modal>
          </div>
        </div>
        <p className="">
          A community dedicated to connecting hospitality members
        </p>
        <div>
          <p className="absolute right-0 bottom-0 p-6 text-right">
            For help, please contact help@hotelhive.org{" "}
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Home;
