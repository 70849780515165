import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import axios from "axios";

const NewsPage = () => {
  const [articles, setArticles] = useState([]);

  //   useEffect(() => {
  //     axios
  //       .get("https://hotelhive-backend.vercel.app/api/news")
  //       .then((response) => {
  //         setArticles(response.data);
  //         console.log(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("There was an error fetching the news articles:", error);
  //       });
  //   }, []);

  useEffect(() => {
    // Simulate fetching data with dummy articles
    const dummyArticles = [
        {
        id: 0,
        title: "Top 10 Trends in the Hospitality Industry in 2024",
        summary:
          "What are the latest trends in the hospitality industry? As a testament to its resilience, agility and innovative spirit...",
        image:
          "https://hospitalityinsights.ehl.edu/hubfs/Hospitality_Trends_2024.jpg",
        link: "https://hospitalityinsights.ehl.edu/hospitality-industry-trends",
      },
      {
        id: 1,
        title: "Marriott: U.S. Market Is Cooling Amid Global Hotel Boom",
        summary:
          "Marriott execs cited a 'normalization' in domestic U.S. vacation bookings, a potential warning sign for the post-pandemic travel frenzy.",
        image:
          "https://skift.com/wp-content/uploads/2024/05/Westin_Washington_DC_Downtown_Next_Gen_Heavenly_Bed.jpg",
        link: "https://skift.com/2024/05/01/marriott-u-s-market-is-cooling-amid-global-hotel-boom/",
      },
      {
        id: 2,
        title: "Hotel Owners Urged To Do Complicated Math on Guest Acquisition",
        summary:
          "Costs of Loyalty, Lifetime Value Add To Complex Equation",
        image:
          "https://costar.brightspotcdn.com/dims4/default/464459f/2147483647/strip/true/crop/2100x1400+0+0/resize/2100x1400!/quality/100/?url=http%3A%2F%2Fcostar-brightspot.s3.us-east-1.amazonaws.com%2F50%2F5f%2Fd9cef0e5412f8aa66e8b97c6964c%2Fihif-partnership.jpg",
        link: "https://www.costar.com/article/1155368417/hotel-owners-urged-to-do-complicated-math-on-guest-acquisition",
      },
      {
        id: 3,
        title: "Embracing Tech for Enhanced Personalization in Hospitality: Balancing Innovation and Human Touch",
        summary:
          "As the hospitality industry navigates staffing challenges and evolving guest expectations, embracing technology becomes paramount.",
        image:
          "https://assets1.hospitalitytech.com/styles/max_width_800/s3/2021-12/marketing%20pc%20ops%20automation%20teaser%20shutterstock_744825085_0.jpg?itok=mfdeZvtD",
        link: "https://hospitalitytech.com/embracing-tech-enhanced-personalization-hospitality-balancing-innovation-and-human-touch",
      },
      {
        id: 4,
        title: "Keeping Hospitality Human: The Importance of a Personal Touch in Hotel Technology Management",
        summary:
          "Technology providers that prioritize personal relationships and live-agent support in an age of automation are a valuable asset.",
        image:
          "https://assets1.hospitalitytech.com/s3fs-public/styles/content_hero_1300/public/298e882e81fe913ed69d.jpg?VersionId=9wtz9dj_ku_sr.eekFeE3K_LekPZ0JHW&h=8a74fa94&itok=sqrwC3Gz",
        link: "https://hospitalitytech.com/keeping-hospitality-human-importance-personal-touch-hotel-technology-management",
      },
      {
        id: 5,
        title: "Water Conservation is the Next Frontier in Hospitality Sustainability",
        summary:
          "There’s a strong business case for water efficiency to join the ranks of energy efficiency as an essential upgrade for hospitality businesses.",
        image:
          "https://assets1.hospitalitytech.com/s3fs-public/styles/max_width_800/public/2024-04/ai_energy_sustainability_water_conservation_0.jpg?VersionId=VaoffYOBbMYkRmOsapSHocfgRFzafhzH&itok=-xWQvXAr",
        link: "https://hospitalitytech.com/water-conservation-next-frontier-hospitality-sustainability",
      },
      {
        id: 6,
        title: "The Science of Sleep",
        summary:
          "How the Travel Industry is Cashing in on Tourism’s Latest Obsession.",
        image: "https://skift.com/wp-content/uploads/2024/04/Science-of-Sleep-rev-1a.gif",
        link: "https://skift.com/2024/04/28/the-science-of-sleep-how-the-travel-industry-is-cashing-on-tourisms-latest-obsession/",
      },
      {
        id: 7,
        title: "Big hotel chains and unbranded-hotel owners find they need each other",
        summary:
          "NEW YORK, March 25 (Reuters) - Independent hotel operators and giant global chains...",
        image:
          "https://www.reuters.com/resizer/v2/https%3A%2F%2Fcloudfront-us-east-2.images.arcpublishing.com%2Freuters%2FPD4FTBXC5RP5LNUSSJD5HPPOWM.jpg?auth=442ef032e0f30878ffbb0d895cd88043c7005bba8691496c72d6a503018d8ab5&width=1200&quality=80",
        link: "https://www.reuters.com/business/big-hotel-chains-unbranded-hotel-owners-find-they-need-each-other-2024-03-25/",
      },
      {
        id: 8,
        title: "How Hotels Will Seduce the Middle-Class Traveler",
        summary:
          "Luxury is fun, and lifestyle properties are hip. Yet mid-market brands that serve middle-class travelers...",
        image:
          "https://skift.com/wp-content/uploads/2024/01/middle-class-hotels-mt.png",
        link: "https://skift.com/2024/01/09/how-hotels-will-seduce-the-middle-class-traveler-megatrends-2024/",
      },
    ];

    setArticles(dummyArticles);
  }, []);

  return (
    <>
      <Navbar />
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Latest News</h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {articles.map((article, index) => (
            <div
              key={index}
              className="border p-4 rounded-lg flex flex-col cursor-pointer"
            >
              <div className="flex flex-row">
                <img
                  src={article.image}
                  alt="Article image"
                  className="w-4/12 h-auto mr-4"
                />
                <div>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline text-lg font-semibold"
                  >
                    {article.title}
                  </a>
                  <p className="text-sm text-gray-600 mt-2">
                    {article.summary}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NewsPage;
