import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";

const Directories = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [fetchingLocations, setFetchingLocations] = useState(false);
  const [schoolSearch, setSchoolSearch] = useState("");
  const [companySearch, setCompanySearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [error, setError] = useState(""); // State for storing any error messages

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://hotelhive-backend.vercel.app/api/profiles"
      ); // Ensure correct API endpoint
      setUsers(response.data);
      setFilteredUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to load users."); // Setting error message
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    applyFilters();
  }, [locationSearch, schoolSearch, companySearch, users, nameSearch]);

  const fetchLocationSuggestions = debounce(async (query) => {
    if (!query) {
      setLocationSuggestions([]);
      return;
    }
    setFetchingLocations(true);
    try {
      const response = await axios.get(
        `https://secure.geonames.org/searchJSON?username=darkevo24&q=${query}&maxRows=10`
      );
      setLocationSuggestions(response.data.geonames); // Adjust based on actual API response structure
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    } finally {
      setFetchingLocations(false);
    }
  }, 300); // Adjust debounce timing as needed

  useEffect(() => {
    fetchLocationSuggestions(locationSearch);
  }, [locationSearch]);

  const applyFilters = () => {
    const result = users.filter((user) => {
      const locationMatch = locationSearch
        ? [user.country, user.state, user.city].some((location) =>
            location.toLowerCase().includes(locationSearch.toLowerCase())
          )
        : true;
      const schoolMatch = schoolSearch
        ? user.school &&
          user.school.toLowerCase().includes(schoolSearch.toLowerCase())
        : true;
      const companyMatch = companySearch
        ? user.company &&
          user.company.toLowerCase().includes(companySearch.toLowerCase())
        : true;

      const nameMatch = nameSearch
        ? (user.firstName + " " + user.lastName)
            .toLowerCase()
            .includes(nameSearch.toLowerCase())
        : true; // New logic to filter based on name search

      return locationMatch && schoolMatch && companyMatch && nameMatch;
    });
    setFilteredUsers(result);
  };

  const handleLocationSelect = (name) => {
    setLocationSearch(name);
    setLocationSuggestions([]); // Clear suggestions after selection
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <aside className="mt-6 border border-gray-200 p-4 mr-6 lg:w-1/4">
            <p className="font-bold">Filters:</p>
            <div className="overflow-y-auto">
              <input
                type="text"
                placeholder="Search by name"
                value={nameSearch}
                onChange={(e) => setNameSearch(e.target.value)} // Update nameSearch state on change
                className="mb-4 mt-2 px-2 py-1 border rounded"
              />
              <input
                type="text"
                placeholder="Location :"
                value={locationSearch}
                onChange={(e) => setLocationSearch(e.target.value)}
                className="mb-4 px-2 py-1 border rounded"
              />
              {fetchingLocations && <div>Loading...</div>}
              {locationSuggestions.length > 0 && (
                <ul className="absolute z-10 list-disc bg-white border mt-1 max-h-60 overflow-auto">
                  {locationSuggestions.map((suggestion) => (
                    <li
                      key={suggestion.geonameId}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleLocationSelect(suggestion.name)}
                    >
                      {suggestion.name}, {suggestion.countryName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </aside>
          <main className="mt-6 lg:w-3/4">
            <div className="overflow-x-auto">
              {isLoading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  className="text-center block mx-auto"
                />
              ) : error ? (
                <p className="text-red-500">{error}</p>
              ) : (
                <table className="table-auto w-full">
                  <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                      <th className="px-4 py-2">Profile Photo</th>
                      <th className="px-4 py-2 text-left">Name</th>
                      <th className="px-4 py-2 text-left">Company</th>
                      <th className="px-4 py-2 text-left">Location</th>
                      <th className="px-4 py-2 text-left">School</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                    {filteredUsers.map((user) => (
                      <tr
                        className="hover:bg-gray-100 cursor-pointer"
                        key={user.id}
                        onClick={() =>
                          (window.location.href = `/profile/${user.email}`)
                        }
                      >
                        <td className="px-4 py-2 flex justify-center items-center">
                          {user.profilePhoto ? (
                            <img
                              src={user.profilePhoto}
                              alt="Profile"
                              className="w-16 h-16 rounded-full"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faUser}
                              className="w-16 h-16 rounded-full"
                            />
                          )}
                        </td>
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.company || "None"}</td>
                        <td>
                          {[user.country, user.state, user.city]
                            .filter(Boolean)
                            .join(", ")}
                        </td>
                        <td>{user.school || "None"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Directories;
