import React from "react";

const SuccessPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-100">
      <h1 className="text-3xl font-bold text-green-800">Payment Successful!</h1>
      <p className="text-green-600">Thank you for your subscription.</p>
      <a href="/main" className="mt-4 text-blue-600 hover:underline">
        Go back to home
      </a>
    </div>
  );
};

export default SuccessPage;
