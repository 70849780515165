import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import axios from "axios";

const SubscriptionPlans = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);

  const email = sessionStorage.getItem("email");

  const fetchSubscription = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `https://hotelhive-backend.vercel.app/api/subscription/${email}`
      );
      setSubscription(data);
      console.log("Subscription data:", data);
    } catch (error) {
      console.error("Error fetching subscription data:", error);
      setSubscription(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, [email]);

  const handleGetStarted = async (plan) => {
    console.log(`Redirecting to subscription process for ${plan}...`);

    const priceIds = {
      Annual: "price_1OqkywLtN0Jn9BWDPtmkPAkN",
      "Month to Month": "price_1OqkzSLtN0Jn9BWDBfMnqUf9",
    };

    try {
      const response = await axios.post(
        "https://hotelhive-backend.vercel.app/create-checkout-session",
        {
          plan_id: priceIds[plan],
        }
      );
      window.location.href = response.data;
    } catch (error) {
      console.error("Error redirecting to checkout:", error.response || error);
    }
  };

  return (
    <>
      <Navbar />
      <div id="price" className="flex flex-col items-center p-10">
        <p className="text-2xl font-bold">Choose Your Plan</p>
        <p className="text-gray-500 lg:w-6/12 text-center mt-4">
          Select the best plan that fits your hotel business needs. All plans
          come with a 14-day free trial.
        </p>
        <div className="flex flex-wrap justify-center gap-10 mt-10">
          {/* Annual Plan */}
          <div className="card cursor-pointer transition-transform duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 border-gray-200 border p-6 rounded-lg flex flex-col items-center w-full lg:w-1/3 shadow-md hover:shadow-lg">
            <p className="text-xl font-bold text-center">Annual Plan</p>
            <p className="text-gray-500 text-center my-4">
              - $10/month (billed annually)
              <br />
              <span className="text-blue-500">- 40% off monthly plan rate</span>
              <br />- No obligation to post or comment
            </p>
            {subscription ? (
              <button
                disabled
                className="w-10/12 bg-gray-600 px-6 py-2 rounded-md text-white absolute bottom-5"
              >
                Already Subscribed
              </button>
            ) : (
              <button
                onClick={() => handleGetStarted("Annual")}
                className="w-10/12 bg-purple-600 px-6 py-2 rounded-md text-white hover:bg-purple-700 absolute bottom-5"
              >
                Get started
              </button>
            )}
          </div>

          {/* Month to Month Plan */}
          <div className="card cursor-pointer transition-transform duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 border-gray-200 border p-6 rounded-lg flex flex-col items-center w-full lg:w-1/3 shadow-md hover:shadow-lg">
            <p className="text-xl font-bold text-center">Month to Month</p>
            <p className="text-gray-500 text-center my-4 mb-16">
              - $25/month (billed monthly)
              <br />
              <span className="text-blue-500">- Free if you post</span>
              <br />- First 30 days free, earn an additional 30 days for each post or comment
            </p>
            {subscription ? (
              <button
                disabled
                className="w-10/12 bg-gray-600 px-6 py-2 rounded-md text-white absolute bottom-5"
              >
                Already Subscribed
              </button>
            ) : (
              <button
                onClick={() => handleGetStarted("Month to Month")}
                className="w-10/12 bg-purple-600 px-6 py-2 rounded-md text-white hover:bg-purple-700 absolute bottom-5"
              >
                Get started
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlans;
