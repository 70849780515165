import React, { useEffect, useState } from "react";
import axios from "axios";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "./navbar";

function ApplicantList({ jobId }) {
  const [applicants, setApplicants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (jobId) {
      fetchApplicants(jobId);
    }
  }, [jobId]);

  // Update the fetchApplicants function inside your ApplicantList component
  const fetchApplicants = async (jobId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://hotelhive-backend.vercel.app/api/jobs/${jobId}/applicants`,
        {
          headers: { Email: sessionStorage.getItem("email") },
        }
      );
      setApplicants(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch applicants", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar />

      <div className=" p-10">
        <p className="mb-4 text-2xl font-semibold">Applicants</p>
        {isLoading ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 table-auto w-full">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Email</th>
                  <th className="py-3 px-6 text-center">Resume</th>
                  <th className="py-3 px-6 text-left">Cover Letter</th>
                </tr>
              </thead>
              <tbody>
                {applicants.length > 0 ? (
                  applicants.map((applicant) => (
                    <tr
                      className="border-b border-gray-200 hover:bg-gray-100"
                      key={applicant.id}
                    >
                      <td className="py-3 px-6 text-left">{applicant.name}</td>
                      <td className="py-3 px-6 text-left">{applicant.email}</td>
                      <td className="py-3 px-6 text-center">
                        <a
                          href={applicant.resume}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="bg-green-500 hover:bg-green-700 text-white font-bold w-40 h-10 rounded mt-2">
                            Download Resume
                          </button>
                        </a>
                      </td>
                      <td className="py-3 px-6 text-left">
                        {applicant.cover_letter}
                      </td>
                    </tr>
                  ))
                ) : (
                  <div className="p-2">
                    <p>There is no applicant at the moment</p>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default ApplicantList;
