import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "./navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function FollowerFollowingPage() {
  const { email } = useParams();
  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [followersSearchTerm, setFollowersSearchTerm] = useState("");
  const [followingsSearchTerm, setFollowingsSearchTerm] = useState("");

  const fetchFollowers = () => {
    axios
      .get(`https://hotelhive-backend.vercel.app/api/users/${email}/followers`)
      .then((response) => {
        setFollowers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching followers:", error);
        setLoading(false);
      });
  };

  const fetchFollowings = () => {
    axios
      .get(`https://hotelhive-backend.vercel.app/api/users/${email}/followings`)
      .then((response) => {
        setFollowings(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching followings:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFollowers();
    fetchFollowings();
  }, [email]); // Make sure to include email as a dependency to fetch data when it changes

  return (
    <>
      <div className="container mx-auto p-8">
        {loading ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h2 className="text-xl font-semibold mb-4">Followers:</h2>
              <input
                type="text"
                placeholder="Search followers..."
                value={followersSearchTerm}
                onChange={(e) => setFollowersSearchTerm(e.target.value)}
                className="border border-gray-300 rounded-md px-3 py-2 mb-4"
              />
              <ul>
                {followers
                  .filter(
                    (follower) =>
                      follower.firstName
                        .toLowerCase()
                        .includes(followersSearchTerm.toLowerCase()) ||
                      follower.lastName
                        .toLowerCase()
                        .includes(followersSearchTerm.toLowerCase()) ||
                      follower.email
                        .toLowerCase()
                        .includes(followersSearchTerm.toLowerCase())
                  )
                  .map((follower) => (
                    <li
                      onClick={() => {
                        window.location.href = "/profile/" + follower.email;
                      }}
                      key={follower.id}
                      className="flex items-center mb-2 cursor-pointer"
                    >
                      <img
                        src={follower.profilePhoto}
                        alt="Profile"
                        className="rounded-full w-8 h-8 object-cover mr-2"
                      />
                      {follower.firstName} {follower.lastName}
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <h2 className="text-xl font-semibold mb-4">Following:</h2>
              <input
                type="text"
                placeholder="Search followings..."
                value={followingsSearchTerm}
                onChange={(e) => setFollowingsSearchTerm(e.target.value)}
                className="border border-gray-300 rounded-md px-3 py-2 mb-4"
              />
              <ul>
                {followings
                  .filter(
                    (following) =>
                      following.firstName
                        .toLowerCase()
                        .includes(followingsSearchTerm.toLowerCase()) ||
                      following.lastName
                        .toLowerCase()
                        .includes(followingsSearchTerm.toLowerCase()) ||
                      following.email
                        .toLowerCase()
                        .includes(followingsSearchTerm.toLowerCase())
                  )
                  .map((following) => (
                    <li
                      onClick={() => {
                        window.location.href = "/profile/" + following.email;
                      }}
                      key={following.id}
                      className="flex items-center mb-2 cursor-pointer"
                    >
                      <img
                        src={following.profilePhoto}
                        alt="Profile"
                        className="rounded-full w-8 h-8 object-cover mr-2"
                      />
                      {following.firstName} {following.lastName}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FollowerFollowingPage;
