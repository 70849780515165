import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faLocation,
  faLocationPin,
  faMessage,
  faPlus,
  faSpinner,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import ReactModal from "react-modal";
import { useMediaQuery } from "react-responsive";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "react-slick";
import imageCompression from "browser-image-compression";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DealPage = () => {
  const [deals, setDeals] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dealData, setDealData] = useState({
    title: "",
    location: "",
    industry: "",
    revenue: "",
    ebitda: "",
    blurb: "",
  });
  const [teaserFile, setTeaserFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [industryFilter, setIndustryFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [revenueFilter, setRevenueFilter] = useState("");
  const [ebitdaFilter, setEbitdaFilter] = useState("");
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [uniqueIndustries, setUniqueIndustries] = useState([]);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [revenueOptions, setRevenueOptions] = useState([]);
  const [ebitdaOptions, setEbitdaOptions] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [hoveredUserId, setHoveredUserId] = useState(null);
  const [followStatuses, setFollowStatuses] = useState({});
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    // Extract unique industries
    const industries = [...new Set(deals.map((deal) => deal.industry))].sort();
    setUniqueIndustries(industries);

    // Extract unique locations
    const locations = [...new Set(deals.map((deal) => deal.location))].sort();
    setUniqueLocations(locations);

    // For revenue and EBITDA, you might want to create ranges or specific options based on your data
    // Here's an example for creating sorted unique options, adjust according to your requirements
    const revenues = [...new Set(deals.map((deal) => deal.revenue))].sort(
      (a, b) => a - b
    );
    setRevenueOptions(revenues);

    const ebitdas = [...new Set(deals.map((deal) => deal.ebitda))].sort(
      (a, b) => a - b
    );
    setEbitdaOptions(ebitdas);
  }, [deals]);

  const filterDeals = () => {
    let tempDeals = [...deals];

    if (industryFilter) {
      tempDeals = tempDeals.filter((deal) => deal.industry === industryFilter);
    }
    if (locationFilter) {
      tempDeals = tempDeals.filter((deal) => deal.location === locationFilter);
    }
    if (revenueFilter) {
      const selectedRange = revenueRanges.find(
        (range) => range.label === revenueFilter
      );
      tempDeals = tempDeals.filter(
        (deal) =>
          deal.revenue >= selectedRange.min && deal.revenue <= selectedRange.max
      );
    }
    if (ebitdaFilter) {
      const selectedRange = ebitdaRanges.find(
        (range) => range.label === ebitdaFilter
      );
      tempDeals = tempDeals.filter(
        (deal) =>
          deal.ebitda >= selectedRange.min && deal.ebitda <= selectedRange.max
      );
    }

    setFilteredDeals(tempDeals);
  };

  useEffect(() => {
    fetchDeals();
  }, []);

  useEffect(() => {
    filterDeals();
  }, [deals, industryFilter, locationFilter, revenueFilter, ebitdaFilter]);

  const fetchDeals = () => {
    setIsLoading(true); // Start loading
    axios
      .get("https://hotelhive-backend.vercel.app/api/deals", {
        headers: { Email: sessionStorage.getItem("email") },
      })
      .then((response) => {
        setDeals(response.data);
        fetchAllFollowStatuses(response.data);
        setIsLoading(false); // Stop loading
        console.log(response.data, "deals");
      })
      .catch((error) => {
        console.error("There was an error fetching the deals: ", error);
        setIsLoading(false); // Stop loading
      });
  };

  const fetchAllFollowStatuses = async (deals) => {
    // Assume each deal has a 'userEmail' property
    const statuses = {};
    for (const deal of deals) {
      try {
        const response = await axios.get(
          `https://hotelhive-backend.vercel.app/api/users/${email}/is-following/${deal.email}`
        );
        statuses[deal.id] = response.data.isFollowing;
      } catch (error) {
        console.error("Error checking follow status for deal:", deal.id, error);
      }
    }
    setFollowStatuses(statuses);
  };

  const handleInputChange = (e) => {
    setDealData({ ...dealData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setTeaserFile([...e.target.files]);
    console.log(e.target.files, "files");
  };

  const handleFileChangePDF = (e) => {
    setPdfFile(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const processImageFile = async (imageFile) => {
    const options = {
      maxSizeMB: 1, // (Optional) Maximum file size you want after compression (1MB in this example)
      maxWidthOrHeight: 1024, // (Optional) maximum width or height of the output image, maintaining aspect ratio
      useWebWorker: true, // (Optional) Offload processing to a web worker
    };

    try {
      // Compress the image file
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow or handle as needed
    }
  };

  const email = sessionStorage.getItem("email");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(dealData).forEach((key) => formData.append(key, dealData[key]));

    try {
      const processedTeaserFiles = await Promise.all(
        [...teaserFile].map((file) => processImageFile(file))
      );

      // Append the processed (resized/compressed) files to FormData
      processedTeaserFiles.forEach((file) => {
        formData.append("teaser_files", file);
      });
      if (pdfFile) {
        formData.append("pdf_file", pdfFile);
      }
      const res = await axios.post(
        "https://hotelhive-backend.vercel.app/api/deals",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Email: sessionStorage.getItem("email"),
          },
        }
      );
      toast.success("Deal posted successfully!");
      fetchDeals();
      setShowModal(false); // Close modal after submission
      setDealData({
        title: "",
        location: "",
        industry: "",
        revenue: "",
        ebitda: "",
        blurb: "",
      }); // Reset form
      setTeaserFile(null); // Reset file input
      setPdfFile(null);
      console.log(res.data, "res");
    } catch (error) {
      console.error("There was an error creating the deal: ", error);
      toast.error("Failed to post the deal.");
    }
    setIsLoading(false);
  };

  const deleteDeal = (dealId) => {
    setIsLoading(true); // Start loading
    axios
      .delete(`https://hotelhive-backend.vercel.app/api/deals/${dealId}`, {
        headers: { Email: email },
      })
      .then((response) => {
        toast.success("Deal deleted successfully!"); // Success toast
        fetchDeals();
      })
      .catch((error) => {
        console.error("There was an error deleting the deal: ", error);
        toast.error("Failed to delete the deal."); // Error toast
      });
    setIsLoading(false); // Stop loading
  };

  const clearFilters = () => {
    setIndustryFilter("");
    setLocationFilter("");
    setRevenueFilter("");
    setEbitdaFilter("");
    setFilteredDeals(deals); // Reset filtered deals to the original list
  };

  const followUser = async (userId, userEmail) => {
    try {
      await axios.post(
        `https://hotelhive-backend.vercel.app/api/users/${userEmail}/follow`,
        {
          email: email,
        }
      );
      setFollowStatuses((prev) => ({ ...prev, [userId]: true }));
      // Additional UI feedback or re-fetching of data can go here
    } catch (error) {
      console.error("Failed to follow user", error);
      // Handle error
    }
  };

  const unfollowUser = async (userId, userEmail) => {
    try {
      await axios.post(
        `https://hotelhive-backend.vercel.app/api/users/${userEmail}/unfollow`,
        {
          email: email,
        }
      );
      setFollowStatuses((prev) => ({ ...prev, [userId]: false }));
      // Additional UI feedback or re-fetching of data can go here
    } catch (error) {
      console.error("Failed to unfollow user", error);
      // Handle error
    }
  };

  const checkIfFollowing = (hoveredUserId, userEmail) => {
    axios
      .get(
        `https://hotelhive-backend.vercel.app/api/users/${email}/is-following/${userEmail}`
      )
      .then((response) => {
        setFollowStatuses((prevStatuses) => ({
          ...prevStatuses,
          [hoveredUserId]: response.data.isFollowing,
        }));
        console.log(followStatuses, "followStatuses");
      })
      .catch((error) =>
        console.error(
          "Error checking following status for user " + hoveredUserId,
          error
        )
      );
  };

  function safelyParseJSON(jsonString) {
    try {
      const parsed = JSON.parse(jsonString);
      // Check if parsed is an array
      return Array.isArray(parsed) ? parsed : [parsed];
    } catch (e) {
      // Return a default value if parsing fails
      return []; // or [defaultImageUrl]
    }
  }

  const revenueRanges = [
    { label: "<50,000", min: 0, max: 49999 },
    { label: "50,000 - 100,000", min: 50000, max: 99999 },
    { label: "100,000 - 200,000", min: 100000, max: 199999 },
    { label: "200,000 - 300,000", min: 200000, max: 299999 },
    { label: "300,000 - 400,000", min: 300000, max: 399999 },
    { label: "400,000 - 500,000", min: 400000, max: 499999 },
    { label: ">500,000", min: 500000, max: Infinity },
  ];

  const ebitdaRanges = [
    { label: "<5,000", min: 0, max: 4999 },
    { label: "5,000 - 10,000", min: 5000, max: 9999 },
    { label: "10,000 - 20,000", min: 10000, max: 19999 },
    { label: "20,000 - 50,000", min: 20000, max: 49999 },
    { label: "50,000 - 75,000", min: 50000, max: 74999 },
    { label: "75,000 - 100,000", min: 75000, max: 99999 },
    { label: ">100,000", min: 100000, max: Infinity },
  ];

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const customStyles = {
    content: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
      width: isMobile ? "80%" : "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const settings = {
    dots: true, // Shows dot indicators at the bottom of the slider
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll on one swipe
  };

  return (
    <>
      <Navbar />

      <div className="flex items-center justify-between  py-4 bg-white shadow-md px-6">
        <div className="flex items-center">
          <ReactModal
            isOpen={showEditModal}
            onRequestClose={() => setShowEditModal(false)}
            style={customStyles}
          >
            {selectedDeal && (
              <div className="p-4 w-full">
                {/* <img
                  src={selectedDeal.teaser_file_url}
                  alt="teaser"
                  className="w-full h-40 object-cover mb-4" // Ensure the image covers the area nicely and add margin bottom for spacing
                /> */}
                {JSON.parse(selectedDeal.teaser_file_url).length > 1 ? (
                  <Slider {...settings}>
                    {JSON.parse(selectedDeal.teaser_file_url).map(
                      (url, index) => (
                        <div key={index}>
                          <img
                            src={url}
                            alt={`Teaser ${index + 1}`}
                            className="w-full h-40 object-cover mb-4"
                          />
                        </div>
                      )
                    )}
                  </Slider>
                ) : (
                  <img
                    src={JSON.parse(selectedDeal.teaser_file_url)[0]}
                    alt={`Teaser `}
                    className="w-full h-40 object-cover mb-4"
                  />
                )}

                <div className="w-full flex items-center justify-between">
                  <div>
                    <h2 className="text-xl font-bold mb-2">
                      {selectedDeal.title}
                    </h2>{" "}
                    <p className="mb-1">{selectedDeal.blurb}</p>{" "}
                    <p className="mb-1 flex items-center">
                      <FontAwesomeIcon
                        icon={faLocationPin}
                        className="text-blue-600"
                      />
                      <span className="font-semibold ml-2">
                        {selectedDeal.location}
                      </span>
                    </p>
                  </div>
                  {selectedDeal.pdf_file_url && (
                    <a
                      href={selectedDeal.pdf_file_url}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold rounded px-4 py-2"
                    >
                      {" "}
                      Download PDF{" "}
                    </a>
                  )}
                </div>
                <div className="w-full flex flex-col items-center mt-3">
                  <div className="flex items-center justify-between w-full">
                    {/* Add margin bottom for spacing */}{" "}
                    {/* Add font weight for emphasis */}
                    <p className="">
                      Posted By: <br></br>
                      <span className="font-semibold">{selectedDeal.name}</span>
                    </p>{" "}
                    <p className="">
                      Industry: <br></br>
                      <span className="font-semibold">
                        {selectedDeal.industry}
                      </span>
                    </p>{" "}
                  </div>
                  <div className="flex items-center justify-between mt-3 w-full">
                    {" "}
                    {/* Add font weight for emphasis */}
                    <p className="">
                      Revenue: <br></br>
                      <span className="font-semibold">
                        ${selectedDeal.revenue}
                      </span>
                    </p>{" "}
                    <p className="">
                      EBITDA: <br></br>
                      <span className="font-semibold">
                        ${selectedDeal.ebitda}
                      </span>
                    </p>{" "}
                  </div>
                </div>
                <button
                  onClick={() => {
                    window.location.href = `/main`;
                  }}
                  className="bg-blue-500 mt-10 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full "
                >
                  View Post
                </button>
                {/* Add font weight for emphasis */}
                {/* Add font weight for emphasis and more spacing */}
                {/* Add any other details you want to show */}
                <button
                  onClick={() => setShowEditModal(false)} // Assuming setShowViewPostModal controls the visibility of the view post modal
                  className="bg-red-500 text-white p-2 rounded hover:bg-red-700 transition duration-300 absolute right-1 top-1" // Add hover effect and transition for better user interaction
                >
                  <FontAwesomeIcon icon={faXmark} />{" "}
                </button>
                {selectedDeal.pdf_file_url && (
                  <Document
                    file={selectedDeal.pdf_file_url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="PDFDocument"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className="PDFPage PDFPageOne"
                      />
                    ))}
                  </Document>
                )}
              </div>
            )}
          </ReactModal>

          {/* Industry Dropdown */}
          {/* <select
          className="mx-2 p-2 border border-gray-300 rounded"
          value={industryFilter}
          onChange={(e) => setIndustryFilter(e.target.value)}
        >
          <option value="">Select Industry</option>
          {uniqueIndustries.map((industry) => (
            <option key={industry} value={industry}>
              {industry}
            </option>
          ))}
        </select> */}

          {/* Location Dropdown */}
          <select
            className="mx-2 p-2 border border-gray-300 rounded"
            value={locationFilter}
            onChange={(e) => setLocationFilter(e.target.value)}
          >
            <option value="">Select Location</option>
            {uniqueLocations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>

          {/* Revenue Dropdown */}
          <select
            className="mx-2 p-2 border border-gray-300 rounded"
            value={revenueFilter}
            onChange={(e) => setRevenueFilter(e.target.value)}
          >
            <option value="">Select Revenue</option>
            {revenueRanges.map((range, index) => (
              <option key={index} value={range.label}>
                {range.label}
              </option>
            ))}
          </select>

          {/* EBITDA Dropdown */}
          <select
            className="mx-2 p-2 border border-gray-300 rounded"
            value={ebitdaFilter}
            onChange={(e) => setEbitdaFilter(e.target.value)}
          >
            <option value="">Select EBITDA</option>
            {ebitdaRanges.map((range, index) => (
              <option key={index} value={range.label}>
                {range.label}
              </option>
            ))}
          </select>
        </div>
        {/* Clear Filters Button */}
        <button
          className="mx-2 bg-red-500 text-white p-2 rounded hover:bg-red-700 transition duration-300"
          onClick={clearFilters}
        >
          Clear Filters
        </button>
      </div>

      <div className="p-5">
        <button
          className="mb-5 bg-blue-500 text-white px-3 py-1 rounded flex items-center hover:bg-blue-700"
          onClick={() => setShowModal(true)}
        >
          <FontAwesomeIcon className="mr-1" icon={faPlus} />
          Post Deal
        </button>
        {isLoading && (
          <FontAwesomeIcon
            icon={faSpinner}
            className="block mx-auto"
          ></FontAwesomeIcon>
        )}
        {showModal && (
          <ReactModal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "500px", // Adjust based on your preference
                width: "90%",
                borderRadius: "8px",
                padding: "20px",
                border: "1px solid #ccc",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              },
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.75)",
              },
            }}
          >
            <form onSubmit={handleSubmit}>
              {/* Form fields for deal information */}
              <input
                type="text"
                name="title"
                placeholder="Title"
                onChange={handleInputChange}
                required
                className="block p-2 w-full mb-2"
              />
              <input
                type="text"
                name="location"
                placeholder="Location"
                onChange={handleInputChange}
                required
                className="block p-2 w-full mb-2"
              />
              <input
                type="text"
                name="industry"
                placeholder="Industry"
                onChange={handleInputChange}
                required
                className="block p-2 w-full mb-2"
              />
              <input
                type="number"
                name="revenue"
                placeholder="Revenue"
                onChange={handleInputChange}
                required
                className="block p-2 w-full mb-2"
              />
              <input
                type="number"
                name="ebitda"
                placeholder="EBITDA"
                onChange={handleInputChange}
                required
                className="block p-2 w-full mb-2"
              />
              <textarea
                name="blurb"
                placeholder="Blurb about this deal (optional)"
                onChange={handleInputChange}
                className="block p-2 w-full mb-2"
              ></textarea>
              <input
                type="file"
                name="teaser_file"
                onChange={handleFileChange}
                className="block p-2 w-full"
                multiple
                required
              />
              <p className="text-sm text-gray-600 mb-2">
                Cover Image (required)
              </p>
              <input
                type="file"
                name="pdf_file"
                onChange={handleFileChangePDF}
                className="block p-2 w-full"
              />
              <p className="text-sm text-gray-600 mb-2">
                PDF File (optional fields)
              </p>
              <button
                type="submit"
                className="bg-blue-500 text-white p-2 rounded"
              >
                Submit
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-red-500 text-white p-2 rounded ml-2"
              >
                Close
              </button>
            </form>
            <FontAwesomeIcon
              onClick={() => setShowModal(false)}
              style={{ color: "red" }}
              className="absolute right-0 top-0 p-2 rounded-full bg-white cursor-pointer w-4 h-4"
              icon={faXmark}
            />
          </ReactModal>
        )}
        <div className="lg:grid grid-cols-5 gap-2">
          {filteredDeals.map((deal) => (
            <div
              key={deal.id}
              className="border border-gray-200 relative h-[500px] mb-4 lg:mb-0"
            >
              <img
                alt="teaser photo"
                className="w-full h-40"
                src={JSON.parse(deal.teaser_file_url)[0]}
              ></img>
              <div className="py-2 px-4">
                {" "}
                <h3 className=" font-bold">{deal.title}</h3>
                <p>{deal.blurb}</p>
                <p className="text-gray-600 text-sm">{deal.location}</p>
                <div className="w-full h-0.5 border border-gray-200 my-3"></div>
                <div className="grid grid-cols-2 gap-5">
                  <div>
                    {hoveredUserId == deal.id && (
                      <div
                        onMouseEnter={() => {
                          setHoveredUserId(deal.id);
                          checkIfFollowing(deal.id, deal.email);
                        }}
                        onMouseLeave={() => {
                          setHoveredUserId(null);
                        }}
                        className="absolute bg-white p-2 rounded-lg w-48 z-10"
                      >
                        <img
                          onClick={() => {
                            window.location.href = `/profile/${deal.email}`;
                          }}
                          src={deal.profilePhotoUrl}
                          alt="profile"
                          className="w-full h-full rounded-full mr-2 cursor-pointer"
                        />
                        <p className="font-bold">{deal.name}</p>
                        <p className="text-blue-600 text-sm">{deal.source}</p>
                        <p className="text-gray-600 text-sm">
                          {deal.country}, {deal.state}, {deal.city}
                        </p>
                        <p className="text-sm font-semibold">{deal.school}</p>
                        <div className="flex items-center w-full mt-2">
                          {followStatuses[deal.user_id] ? (
                            <button
                              onClick={() =>
                                unfollowUser(deal.user_id, deal.email)
                              }
                              className="px-4 py-2 bg-gray-500 w-full mr-4 text-white rounded-lg hover:bg-gray-700"
                            >
                              Followed
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                followUser(deal.user_id, deal.email)
                              }
                              className="px-4 py-2 bg-blue-500 w-full mr-4 text-white rounded-lg hover:bg-blue-700"
                            >
                              Follow
                            </button>
                          )}
                          <button
                            onClick={() => {
                              window.location.href = "/chat";
                            }}
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
                          >
                            <FontAwesomeIcon icon={faMessage}></FontAwesomeIcon>
                          </button>
                        </div>
                      </div>
                    )}
                    <p>Posted By</p>
                    <div
                      onMouseEnter={() => {
                        setHoveredUserId(deal.id);
                      }}
                      onMouseLeave={() => {
                        setHoveredUserId(null);
                      }}
                      className="flex items-center"
                    >
                      <img
                        src={deal.profilePhotoUrl}
                        alt="profile"
                        className="w-6 h-6 rounded-full mr-2"
                      />
                      <p className="font-bold">{deal.name}</p>
                    </div>
                  </div>
                  <div>
                    <p>Industry</p>
                    <p className="font-bold">{deal.industry}</p>
                  </div>
                  <div>
                    <p>Revenue</p>
                    <p className="font-bold">${deal.revenue}</p>
                  </div>
                  <div>
                    <p>Ebitda</p>
                    <p className="font-bold">${deal.ebitda}</p>
                  </div>
                </div>
              </div>
              {deal.email === email && ( // Check if the logged-in user is the owner
                <button
                  onClick={() => deleteDeal(deal.id)}
                  className="absolute -right-2 -top-3 hover:opacity-80 rounded-full bg-white p-1 text-white shadow-lg hover:bg-red-700 transition duration-300 ease-in-out"
                  title="Delete Deal"
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="text-red-500 w-4 h-4" // Adjusted for color and size
                  ></FontAwesomeIcon>
                </button>
              )}
              <button
                onClick={() => {
                  setSelectedDeal(deal);
                  setShowEditModal(true); // Assuming you have a state to control the visibility of the view post modal
                }}
                className="bg-blue-500 mt-20 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full absolute bottom-0"
              >
                View Post
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DealPage;
