import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "./navbar";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPaperPlane,
  faPaperclip,
  faPlus,
  faSearch,
  faSpinner,
  faTrash,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

const ChatApp = () => {
  const [users, setUsers] = useState([]);
  const [usersModal, setUsersModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentChatUser, setCurrentChatUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [addedUsers, setAddedUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [unreadCount, setUnreadCount] = useState(0); // Initialize with the actual count
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editMessageId, setEditMessageId] = useState(null);
  const [editContent, setEditContent] = useState("");

  const email = sessionStorage.getItem("email");

  const startEditing = (message) => {
    setIsEditing(true);
    setEditMessageId(message.id);
    setEditContent(message.content);
  };

  const saveEdit = async () => {
    try {
      const response = await axios.put(
        `https://hotelhive-backend.vercel.app/api/messages/edit/${editMessageId}`,
        {
          content: editContent,
        },
        {
          headers: { Email: sessionStorage.getItem("email") }, // Include any necessary headers
        }
      );
      fetchMessages(); // Refresh messages to update the UI
      setIsEditing(false);
      setEditMessageId(null);
      setEditContent("");
    } catch (error) {
      console.error("Failed to edit message:", error);
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const addUserToChat = async (email) => {
    try {
      await axios.post(
        `https://hotelhive-backend.vercel.app/api/messages/add_user`,
        {
          email: email,
        }
      );
      fetchUsers();
      // Optionally, display a message or update UI to indicate the user was added
    } catch (error) {
      console.error("Failed to add user to chat:", error);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    fetchUsers();
    fetchUserModal();
  }, []);

  const fetchUserModal = async () => {
    try {
      const res = await axios.get(
        "https://hotelhive-backend.vercel.app/api/profiles",
        {
          headers: { Email: sessionStorage.getItem("email") },
        }
      );
      setUsersModal(res.data);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const res = await axios.get(
        "https://hotelhive-backend.vercel.app/api/profiles-with-last-message",
        {
          headers: { Email: sessionStorage.getItem("email") },
        }
      );
      setUsers(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  useEffect(() => {
    if (currentChatUser) {
      fetchMessages(currentChatUser.id);
    }
  }, [currentChatUser]);

  const fetchMessages = async () => {
    setIsLoadingMessages(true);
    try {
      const response = await axios.get(
        `https://hotelhive-backend.vercel.app/api/messages/${currentChat.id}`,
        {
          headers: { Email: sessionStorage.getItem("email") },
        }
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    } finally {
      setIsLoadingMessages(false);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && !selectedFile) return;

    const formData = new FormData();
    formData.append("content", newMessage);
    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      await axios.post(
        `https://hotelhive-backend.vercel.app/api/messages/${currentChat.id}`,
        formData, // Send formData instead of JSON
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Email: sessionStorage.getItem("email"),
          },
        }
      );
      setNewMessage("");
      setSelectedFile(null); // Reset file input after sending
      fetchMessages(); // Refresh messages
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const markMessageAsRead = async (userEmail) => {
    try {
      await axios.post(
        `https://hotelhive-backend.vercel.app/api/messages/mark-as-read`,
        { email: userEmail }
      );

      setUnreadCount((prevCount) => prevCount - 1);
      // Optionally, update the UI or state to reflect the change
    } catch (error) {
      console.error("Failed to mark message as read:", error);
    }
  };

  const fetchUnreadMessagesCount = () => {
    axios
      .get(
        `https://hotelhive-backend.vercel.app/api/messages/unread-count?email=${email}`
      )
      .then((response) => {
        setUnreadCount(response.data.unreadCount);
      })
      .catch((error) =>
        console.error("Error fetching unread messages count", error)
      );
  };

  // Example adjustment for setting current chat user and chat
  const selectUserForChat = (user) => {
    console.log(user, "user");
    setCurrentChatUser(user);
    // Assuming you have a mechanism to retrieve or set the current chat session ID related to the user
    setCurrentChat({ id: user.id }); // Adjust according to your data model
    fetchMessages(user.id);
    markMessageAsRead(user.email);
  };

  useEffect(() => {
    if (email) {
      fetchUnreadMessagesCount();
    }
  }, [email]);

  const deleteMessage = async (messageId) => {
    try {
      const response = await axios.delete(
        `https://hotelhive-backend.vercel.app/api/messages/delete/${messageId}`,
        {
          headers: { Email: sessionStorage.getItem("email") }, // Include any necessary headers
        }
      );
      fetchMessages(); // Refresh messages to update the UI
    } catch (error) {
      console.error("Failed to delete message:", error);
    }
  };

  useEffect(() => {
    const selectedUsersJSON = localStorage.getItem("selectedUser");
    if (selectedUsersJSON) {
      const selectedUsers = JSON.parse(selectedUsersJSON); // Retrieve the selected users
      setCurrentChatUser(selectedUsers);
      if (localStorage.getItem("fromProfile") === "true") {
        setCurrentChat({ id: selectedUsers.userId });
      } else {
        setCurrentChat({ id: selectedUsers.user_id });
      }
      fetchMessages();
      markMessageAsRead(selectedUsers.email);
    }
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const customStyles = {
    content: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
      width: isMobile ? "80%" : "40%",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 50,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <div>
      <Navbar />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        contentLabel="Select Users"
        style={customStyles}
      >
        <div className="relative w-full flex flex-col items-center">
          {/* <h2>Select Users to Add</h2> */}
          <button className="absolute -top-2 right-0" onClick={toggleModal}>
            <FontAwesomeIcon style={{ color: "red" }} icon={faXmark} />
          </button>
          <ul className="w-2/3">
            {usersModal.map((user) => (
              <li
                className="flex items-center border border-gray-200 rounded-lg mb-2 p-2"
                key={user.id}
              >
                {user.profilePhoto ? (
                  <img
                    src={user.profilePhoto}
                    className="w-6 h-6 rounded-full mr-2"
                  />
                ) : (
                  <FontAwesomeIcon
                    className="w-6 h-6 rounded-full mr-2"
                    icon={faUser}
                  />
                )}
                {user.firstName} {user.lastName}
                <button
                  className="bg-blue-500 text-white px-2 py-1 hover:bg-blue-700 rounded-lg block ml-auto"
                  onClick={() => addUserToChat(user.email)}
                >
                  Add
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
      <div
        className="flex flex-1 overflow-hidden"
        style={{ height: "calc(100vh - 60px)" }}
      >
        <div className="w-1/3 overflow-auto border-r-2 p-2">
          {/* <button
            onClick={toggleModal}
            className="add-user-button block ml-auto bg-blue-500 text-white px-2 py-1 rounded-lg hover:bg-blue-700"
          >
            <FontAwesomeIcon icon={faPlus} /> Add People
          </button> */}
          <div className="search-box p-4">
            <input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input px-4 py-2 border rounded-lg"
              style={{ marginRight: "8px" }}
            />
          </div>
          {isLoadingUsers ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              className="block mx-auto mt-2"
            />
          ) : (
            filteredUsers
              .filter((user) => user.email !== email)
              .filter((user) =>
                localStorage.getItem("selectedUsers")
                  ? localStorage.getItem("selectedUsers").includes(user.id)
                  : false
              )
              .sort((a, b) => {
                // Users with unread messages come first
                if (a.hasUnreadMessages && !b.hasUnreadMessages) {
                  return -1;
                }
                if (!a.hasUnreadMessages && b.hasUnreadMessages) {
                  return 1;
                }
                // If both have unread messages or both don't, sort by last message time (newest first)
                return (
                  new Date(b.lastMessageTime) - new Date(a.lastMessageTime)
                );
              })
              .map((user) => (
                <div
                  key={user.id}
                  onClick={() => selectUserForChat(user)}
                  className={`cursor-pointer p-3 flex items-center relative ${
                    currentChatUser && currentChatUser.id === user.id
                      ? "bg-gray-100"
                      : "hover:bg-gray-100"
                  }`}
                >
                  {user.profilePhoto ? (
                    <img
                      src={user.profilePhoto}
                      alt="Profile"
                      className="w-10 h-10 rounded-full mr-2"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faUser}
                      className="w-10 h-10 rounded-full mr-2"
                    />
                  )}
                  <div
                    style={{
                      fontWeight: user.hasUnreadMessages ? "bold" : "normal",
                    }}
                  >
                    <div>
                      {user.firstName} {user.lastName}
                    </div>
                    <div className="text-sm text-gray-500">
                      {user.lastMessage || "No messages"}
                    </div>
                  </div>
                  <div className="absolute right-2 top-2 text-sm">
                    {new Date(user.lastMessageTime).toLocaleTimeString([], {
                      hour: "2-digit", // Use 2-digit hour representation
                      minute: "2-digit", // Use 2-digit minute representation
                      hour12: true, // Use 12-hour format (set to false for 24-hour format)
                    })}
                  </div>
                </div>
              ))
          )}
        </div>
        <div className="w-2/3 flex flex-col">
          <div className="overflow-auto p-4 flex-grow">
            {isLoadingMessages ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="block mx-auto mt-2"
              />
            ) : (
              messages.map((msg, index) => {
                return (
                  <div
                    key={index}
                    className={`bg-gray-200 p-2 m-2 rounded-md ${
                      msg.sender_id === currentChatUser?.id
                        ? "mr-auto bg-gray-50 w-2/3"
                        : "ml-auto bg-green-100 w-2/3"
                    }`}
                  >
                    {isEditing && editMessageId === msg.id ? (
                      <div>
                        <input
                          type="text"
                          value={editContent}
                          onChange={(e) => setEditContent(e.target.value)}
                        />
                        <button
                          className="text-blue-500 ml-2"
                          onClick={saveEdit}
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <div>
                        {msg.content}
                        <div className="flex items-center justify-end">
                          {msg.sender_id != currentChatUser?.id && (
                            <button
                              className="mr-3"
                              onClick={() => startEditing(msg)}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          )}
                          {msg.sender_id != currentChatUser?.id && (
                            <button
                              onClick={() => deleteMessage(msg.id)}
                              className=""
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    {msg.fileUrl && (
                      <a
                        href={msg.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        Download Attached File
                      </a>
                    )}
                    <div className="text-right text-xs text-gray-500">
                      {new Date(msg.timestamp).toLocaleTimeString([], {
                        hour: "2-digit", // Use 2-digit hour representation
                        minute: "2-digit", // Use 2-digit minute representation
                        hour12: true, // Use 12-hour format (set to false for 24-hour format)
                      })}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <form className="p-4 border-t-2 flex" onSubmit={sendMessage}>
            {/* Invisible file input */}
            <input
              id="fileInput"
              type="file"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              style={{ display: "none" }}
            />
            {/* Paperclip icon for file input */}
            <button
              type="button" // Prevent form submission when clicking the icon
              className="p-2"
              onClick={() => document.getElementById("fileInput").click()}
            >
              <FontAwesomeIcon icon={faPaperclip} />
            </button>
            <input
              className="flex-grow p-2 border rounded-l-md"
              type="text"
              placeholder="Type your message here..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />

            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded-r-md"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;
